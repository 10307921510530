import { StateController } from 'state-controller';
import { Node, Edge, Connection } from 'reactflow';
import { v4 as uuidV4 } from 'uuid';
import { AppState, GetStateFunction } from 'redux/store';
import { t } from 'setup-localization';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { MODALS } from 'modules/root-modals/modals';
import FireMinusIcon from 'icons/fire-minus';
import { Trans } from 'react-i18next';
import { BreadcrumbItem } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import { IdName, IdNameIsActive, IdVersion, PaginationData } from 'types/common-types';
import { validators } from 'utils/validator';
import { ProductVersion, ProductDataWithProductVersions } from 'services/products.model';
import { ProductsService } from 'services/products.service';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductConfiguration, WorkflowProductConfiguration } from 'services/product-configurations.model';
import { WorkflowTemplateItemsService } from 'services/workflow-template-items.service';
import { WorkflowTemplatesService } from 'services/workflow-templates.service';
import { WorkflowTaskTemplatesService } from 'services/workflow-task-templates.service';
import { WorkflowEdgesService } from 'services/workflow-edges.service';
import { Actions as ProductWorkFlowLayoutActions } from 'pages/product-flow/product-flow-layout/product-flow-layout.controller';
import { PriorityEnum } from 'types/priority-enums';
import { versionName } from 'utils/version-name';
import { notify } from 'notifications';
import {
  CurrentWorkflowStats,
  WorkflowComponent,
  WorkflowTasksStats,
  WorkflowTaskTemplate,
  WorkflowTemplate,
} from 'services/workflow-templates.model';
import { ActiveTickIcon } from 'icons/active-tick';
import { Products2Icon } from 'icons/products-2';
import { debounce } from 'utils/debounce';
import { Actions as ProductConfigurationController } from '../product/controllers/product-configurations.controller';
import s from './workflow-template.module.scss';

export type TaskData = {
  id: string;
  name: string;
  created_at: string;
  numberOfOverlappedItems: number;
  configuration?: null | IdNameIsActive;
  variant?:
    | (IdNameIsActive & {
        is_nested_workflow_template_selected: boolean;
      })
    | null;
  version?: null | IdNameIsActive;
  product?:
    | null
    | (IdNameIsActive & {
        is_new_version_available: boolean;
      });
  intersectionWarning?: boolean;
  isPreviewMode?: boolean;
};

export type ProductData = {
  id: string;
  name: string;
  created_at: string;
  numberOfOverlappedItems: number;
  configuration?: IdNameIsActive;
  variant?:
    | (IdNameIsActive & {
        is_nested_workflow_template_selected: boolean;
      })
    | null;
  product?: IdNameIsActive & {
    is_new_version_available: boolean;
  };
  intersectionWarning?: boolean;
  isPreviewMode?: boolean;
  version?: IdNameIsActive;
};

export type ProductCandidate = {
  product: IdNameIsActive | null;
  version:
    | (IdNameIsActive & {
        is_new_version_available: boolean;
      })
    | null;
  configuration: IdNameIsActive | null;
  variant:
    | (IdNameIsActive & {
        is_nested_workflow_template_selected?: boolean;
      })
    | null;
};

export type nodeChangedData = {
  dragging: boolean;
  id: string;
  position: {
    x: number;
    y: number;
  };
  positionAbsolute: {
    x: number;
    y: number;
  };
  type: string;
};

export type NodeData = TaskData | ProductData;
export type TaskNode = Node<TaskData>;
export type WorkflowNode = Node<ProductData>;
export type Nodes = Array<TaskNode | WorkflowNode>;

export type CanvasWorkflow = {
  id: string;
  productId: string;
  isLoading: boolean;
  isNodeUpdating: boolean;
  isNodeDeleting: boolean;
  isItemLoading: boolean;
  isFullScreen: boolean;
  deletingItems: string[];
  name: string;
  path: IdName[];
  nodes: Array<TaskNode | WorkflowNode>;
  edges: Array<Edge>;
  allTasksSum: WorkflowTasksStats;
  currentWorkflow: CurrentWorkflowStats;
  components: WorkflowComponent[];
  validation: { [key: string]: string[] };
  prevStates: {
    nodesCursor: number;
    edgesCursor: number;
    nodes: Array<Array<TaskNode | WorkflowNode>>;
    edges: Array<Array<Edge>>;
  };
  updatePositionTimeout: any;
  selectedEdge: Edge;
  changedPositionNodeId: string;
  versions?: ProductVersion[];
  breadcrumbs: BreadcrumbItem[];
  is_active: boolean;
};

export type WorkflowEditorState = {
  isConnectionInProgress: boolean;
  workflow: CanvasWorkflow;
  taskAutoAssignmentEnabled: boolean;
  workflowTaskTemplates: Array<WorkflowTaskTemplate>;
  taskCandidateOrEdit: {
    id?: string;
    nodeItem: TaskNode | WorkflowNode;
    canvasPosition: { x: number; y: number };
    viewportPosition: { x: number; y: number };
  } | null;
  additionalTaskModal: {
    isOpen: boolean;
    data: Array<WorkflowTaskTemplate>;
  };
  addEditProductCandidateModal: {
    isEdit: boolean;
    isOpen: boolean;
    isLoading: boolean;
    didTryToSave?: boolean;
    data: ProductCandidate;
    pagination: PaginationData;
    isLoadingConfiguration: boolean;
    initProductDataForEdit: WorkflowNode;
    workflowTemplates: WorkflowTemplate[];
    productPosition: { x: number; y: number };
    productData: ProductDataWithProductVersions[];
    productConfigurationData: WorkflowProductConfiguration[];
    productDataValidationText: {
      product: string;
      version: string;
      configuration: string;
      variant: string;
    };
  };
};

const defaultState: WorkflowEditorState = {
  isConnectionInProgress: false,
  workflowTaskTemplates: [],
  taskAutoAssignmentEnabled: false,
  workflow: {
    id: '',
    productId: '',
    isLoading: false,
    isNodeUpdating: false,
    isNodeDeleting: false,
    isItemLoading: false,
    isFullScreen: false,
    deletingItems: [],
    path: [],
    name: '',
    nodes: [],
    edges: [],
    allTasksSum: { tasksNumber: 0, timeLimit: 0, reward: 0 },
    currentWorkflow: {
      workflowTasks: { tasksNumber: 0, timeLimit: 0, reward: 0 },
      additionalTasks: { tasksNumber: 0, timeLimit: 0, reward: 0 },
    },
    components: [],
    validation: {},
    prevStates: {
      nodesCursor: 0,
      edgesCursor: 0,
      nodes: [],
      edges: [],
    },
    updatePositionTimeout: null,
    selectedEdge: null,
    changedPositionNodeId: null,
    versions: [],
    breadcrumbs: [],
    is_active: false,
  },
  taskCandidateOrEdit: null,
  additionalTaskModal: {
    isOpen: false,
    data: [],
  },
  addEditProductCandidateModal: {
    isEdit: false,
    isLoading: false,
    isLoadingConfiguration: false,
    isOpen: false,
    didTryToSave: false,
    initProductDataForEdit: null,
    productData: [],
    productConfigurationData: [],
    productPosition: null,
    workflowTemplates: [],
    pagination: {
      total: 0,
      perPage: 10,
      next: 0,
      lastPage: 0,
      currentPage: 1,
    },
    data: {
      product: null,
      version: null,
      configuration: null,
      variant: null,
    },
    productDataValidationText: {
      product: '',
      version: '',
      configuration: '',
      variant: '',
    },
  },
};

const stateController = new StateController<WorkflowEditorState>('WORKFLOW_EDITOR', defaultState);

export class Actions {
  public static initWorkflowPage(id: string) {
    return async (dispatch) => {
      try {
        dispatch(ProductWorkFlowLayoutActions.saveProductWithConfigurationRoad());
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isLoading: true,
            },
          })),
        );
        const data = await WorkflowTemplatesService.getWorkflowById(id);
        dispatch(ProductWorkFlowLayoutActions.setProductRoad(data.id, data.name));

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskAutoAssignmentEnabled: data.prefer_auto_assign_users_with_prior_experience,
            workflow: {
              ...prevState.workflow,
              id,
              productId: data.product_id,
              name: data.name,
              path: data.path,
              nodes: data.workflowTemplateItems.map((item) => {
                if (item.workflowTaskTemplate) {
                  return {
                    id: item.id,
                    type: 'task',
                    draggable: true,
                    position: { x: item.position_x, y: item.position_y },
                    data: {
                      created_at: item.created_at,
                      id: item.workflowTaskTemplate.id,
                      name: item.workflowTaskTemplate.name,
                      configuration: null,
                      variant: null,
                      product: null,
                      version: null,
                      tasksNumber: 1,
                      timeLimit: item.workflowTaskTemplate.time_limit,
                      reward: item.workflowTaskTemplate.basic_reward,
                    },
                  };
                }
                return {
                  id: item.id,
                  type: 'workflow',
                  draggable: true,
                  position: { x: item.position_x, y: item.position_y },
                  data: {
                    created_at: item.created_at,
                    id: item.nestedWorkflowTemplate.id,
                    name: item.nestedWorkflowTemplate.product.name,
                    product: {
                      id: item.nestedWorkflowTemplate.product.product_draft_id,
                      name: item.nestedWorkflowTemplate.product.name,
                      is_active: item.nestedWorkflowTemplate.product.is_source_active || false,
                      is_new_version_available: item.nestedWorkflowTemplate.product.is_new_version_available,
                    },
                    version: {
                      id: item.nestedWorkflowTemplate.product.id,
                      name: versionName(item.nestedWorkflowTemplate.product.version),
                      is_active: item.nestedWorkflowTemplate.product.is_active,
                    },
                    configuration: {
                      id: item.nestedWorkflowTemplate.configuration.id,
                      name: item.nestedWorkflowTemplate.configuration.name,
                      is_active: item.nestedWorkflowTemplate.configuration.is_active,
                    },
                    variant: {
                      id: item.nestedWorkflowTemplate.id,
                      name: item.nestedWorkflowTemplate.name,
                      is_active: item.nestedWorkflowTemplate?.is_active,
                      is_nested_workflow_template_selected: item.is_nested_workflow_template_selected,
                    },
                  },
                };
              }),
              edges: data.edges.map((edge) => ({
                id: edge.id,
                source: edge.source_id,
                target: edge.target_id,
                type: 'smoothstep',
              })),
              versions: [
                {
                  id,
                  is_main_version: false,
                  is_published: true,
                  published_at: data.published_at,
                  version: versionName(data.version),
                  processed_count: 1, // TODO: Temp
                },
              ],
              breadcrumbs: data.breadcrumbs,
              ...data.workflowTasksIndicators,
              is_active: data.is_active,
            },
            workflowTaskTemplates: data.workflowTaskTemplates,
          })),
        );

        dispatch(Actions.validateIntersections());
        dispatch(Actions.validateTemplateHasElements());
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static clearWorkflowPage() {
    return async (dispatch) => {
      dispatch(
        stateController.setState(() => ({
          ...defaultState,
          workflow: {
            ...defaultState.workflow,
            // id: prevState.workflow.id,
          },
        })),
      );
    };
  }

  public static openTaskCandidateOrEditMenu(
    canvasPosition: { x: number; y: number },
    viewportPosition: { x: number; y: number },
    id?: string,
  ) {
    return (dispatch, getState: () => AppState) => {
      let existingNodeToEdit: WorkflowNode | TaskNode;
      if (id) {
        existingNodeToEdit = getState().workflow_editor.workflow.nodes.find((item) => item.id === id);
      }
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskCandidateOrEdit: {
            ...prevState.taskCandidateOrEdit,
            ...(id
              ? {
                  id: existingNodeToEdit.id,
                  nodeItem: { ...existingNodeToEdit },
                }
              : {
                  nodeItem: {
                    type: 'task',
                  },
                }),
            canvasPosition,
            viewportPosition,
          },
        })),
      );
    };
  }

  public static closeTaskCandidateMenu() {
    return (dispatch) => {
      dispatch(stateController.setState((prevState) => ({ ...prevState, taskCandidateOrEdit: null })));
    };
  }

  public static updateTasksIndicators() {
    return async (dispatch, getState: GetStateFunction) => {
      const workflowTemplateId = getState().workflow_editor.workflow.id;

      const tasksData = await WorkflowTemplatesService.getWorkflowTasksInfo(workflowTemplateId);

      dispatch(stateController.setState((prev) => ({ ...prev, workflow: { ...prev.workflow, ...tasksData } })));
    };
  }

  public static createTask(position?: { x: number; y: number }, name?: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id: workflow_template_id, productId } = getState().workflow_editor.workflow;

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: true,
            },
          })),
        );
        const newTaskData = await WorkflowTaskTemplatesService.create({
          description: '',
          is_additional: false,
          is_control: false,
          name,
          priority: PriorityEnum.Lowest,
          time_limit: 0,
          workflow_template_id,
        });
        const newTaskNodeItem = await WorkflowTemplateItemsService.create({
          nested_workflow_template_id: null,
          position_x: position.x,
          position_y: position.y,
          workflow_task_template_id: newTaskData.id,
          workflow_template_id,
        });
        await dispatch(Actions.updateTasksIndicators());

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              nodes: [
                ...prevState.workflow.nodes,
                {
                  id: newTaskNodeItem.id,
                  type: 'task',
                  draggable: true,
                  position: { x: position.x, y: position.y },
                  data: {
                    id: newTaskData.id,
                    name,
                    created_at: newTaskData.created_at,
                    configuration: '',
                    variant: '',
                    version: '',
                  },
                },
              ],
            },
            taskCandidateOrEdit: null,
          })),
        );
        await ProductsService.updateProduct(productId);
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: false,
            },
          })),
        );
        dispatch(Actions.validateIntersections());
        dispatch(Actions.validateTemplateHasElements());
      }
    };
  }

  public static updateTask(id: string, name: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: true,
            },
          })),
        );
        const taskNode = getState().workflow_editor.workflow.nodes.find((node) => node.id === id);
        const { productId } = getState().workflow_editor.workflow;
        await WorkflowTaskTemplatesService.update(taskNode.data.id, {
          name,
        });
        await ProductsService.updateProduct(productId);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              nodes: prevState.workflow.nodes.map((item) => {
                if (item.id === taskNode.id) {
                  return {
                    ...item,
                    data: {
                      ...item.data,
                      name,
                    },
                  };
                }
                return item;
              }),
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: false,
            },
            taskCandidateOrEdit: null,
          })),
        );
        dispatch(Actions.validateIntersections());
      }
    };
  }

  public static updateWorkflow(workflowTemplateId: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(Actions.productDataValidation());
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: true,
            },
            addEditProductCandidateModal: {
              ...prevState.addEditProductCandidateModal,
              didTryToSave: true,
            },
          })),
        );
        const { data, productPosition, initProductDataForEdit } = getState().workflow_editor.addEditProductCandidateModal;
        const { productId } = getState().workflow_editor.workflow;
        const postData = {
          nested_workflow_template_id: data.variant.id,
          is_nested_workflow_template_selected: data.variant.id !== 'null',
          nested_workflow_template_configuration_id: data.configuration.id,
          workflow_template_id: workflowTemplateId,
          position_x: productPosition.x,
          position_y: productPosition.y,
          workflow_task_template_id: null,
        };
        await WorkflowTemplateItemsService.update(initProductDataForEdit.id, postData);
        await ProductsService.updateProduct(productId);
        await dispatch(Actions.updateTasksIndicators());
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskCandidateOrEdit: null,
            workflow: {
              ...prevState.workflow,
              nodes: prevState.workflow.nodes.map((item) => {
                if (item.id === initProductDataForEdit.id) {
                  return {
                    ...item,
                    data: {
                      ...item.data,
                      id: data.variant.id,
                      name: data.product.name,
                      product: { ...data.product, is_new_version_available: data.version.is_new_version_available },
                      configuration: data.configuration,
                      variant: { ...data.variant, is_nested_workflow_template_selected: data.variant.id !== 'null' },
                      version: data.version,
                    },
                  };
                }
                return item;
              }),
            },
          })),
        );
        dispatch(Actions.closeModal('addEditProductCandidateModal'));
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: false,
            },
          })),
        );
      }
    };
  }

  public static duplicateWorkflowItem(id: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { nodes, productId } = getState().workflow_editor.workflow;
      const source = nodes.find((item) => item.id === id);
      try {
        await ProductsService.updateProduct(productId);
        const newItem = await WorkflowTemplateItemsService.duplicate({ id });
        const newNode: TaskNode | WorkflowNode =
          source.type === 'workflow'
            ? {
                id: newItem.id,
                type: 'workflow',
                draggable: true,
                selected: false,
                position: { x: source.position.x, y: source.position.y },
                data: {
                  numberOfOverlappedItems: 0,
                  created_at: newItem.created_at,
                  id: source.data.id,
                  name: source.data.name,
                  product: {
                    id: source.data.product.id,
                    is_active: source.data.product.is_active,
                    name: source.data.product.name,
                    is_new_version_available: source.data.product.is_new_version_available,
                  },
                  configuration: {
                    id: source.data.configuration.id,
                    is_active: source.data.configuration.is_active,
                    name: source.data.configuration.name,
                  },
                  variant: {
                    id: source.data.variant.id,
                    is_active: source.data.variant.is_active,
                    name: source.data.variant.name,
                    is_nested_workflow_template_selected: source.data.variant.is_nested_workflow_template_selected,
                  },
                  version: {
                    id: source.data.version.id,
                    is_active: source.data.version.is_active,
                    name: source.data.version.name,
                  },
                },
              }
            : {
                id: newItem.id,
                type: 'task',
                draggable: true,
                selected: false,
                position: { x: source.position.x, y: source.position.y },
                data: {
                  numberOfOverlappedItems: 0,
                  created_at: newItem.created_at,
                  id: newItem.workflow_task_template_id,
                  name: source.data.name,
                  configuration: null,
                  variant: null,
                  product: null,
                  version: null,
                },
              };

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              nodes: [...prevState.workflow.nodes, { ...newNode }],
            },
            taskCandidateOrEdit: null,
          })),
        );
        await dispatch(Actions.updateTasksIndicators());
        dispatch(Actions.validateIntersections());
        notify.success('Component is duplicated');
      } catch (error) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              nodes,
            },
            taskCandidateOrEdit: null,
          })),
        );
      }
    };
  }

  public static createWorkflow(position?: { x: number; y: number }) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(Actions.productDataValidation());
        const { data, productPosition } = getState().workflow_editor.addEditProductCandidateModal;
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: true,
            },
            addEditProductCandidateModal: {
              ...prevState.addEditProductCandidateModal,
              didTryToSave: true,
            },
          })),
        );
        const { productDataValidationText } = getState().workflow_editor.addEditProductCandidateModal;
        const { id: workflow_template_id, productId } = getState().workflow_editor.workflow;
        if (Object.keys(productDataValidationText).every((key) => !productDataValidationText[key])) {
          const workflowNodeItem = await WorkflowTemplateItemsService.create({
            nested_workflow_template_id: data.variant.id,
            nested_workflow_template_configuration_id: data.configuration.id,
            position_x: productPosition.x,
            position_y: productPosition.y,
            workflow_task_template_id: null,
            workflow_template_id,
          });
          const newNode: WorkflowNode = {
            id: workflowNodeItem.id,
            type: 'workflow',
            position: position || productPosition,
            data: {
              numberOfOverlappedItems: 0,
              created_at: workflowNodeItem.created_at,
              id: data?.variant.id,
              name: data?.product?.name,
              product: {
                id: data?.product?.id,
                name: data?.product?.name,
                is_active: data?.product?.is_active,
                is_new_version_available: data?.version?.is_new_version_available,
              },
              configuration: {
                id: data?.configuration?.id,
                name: data?.configuration?.name,
                is_active: data?.configuration?.is_active,
              },
              variant: {
                id: data?.variant?.id,
                name: data?.variant?.name,
                is_active: data?.variant?.is_active,
                is_nested_workflow_template_selected: true,
              },
              version: {
                id: data?.version?.id,
                name: data?.version?.name,
                is_active: data?.version?.is_active,
              },
            },
          };
          await ProductsService.updateProduct(productId);
          await dispatch(Actions.updateTasksIndicators());

          dispatch(
            stateController.setState((prevState) => ({
              ...prevState,
              workflow: {
                ...prevState.workflow,
                nodes: [...prevState.workflow.nodes, newNode],
              },
            })),
          );
          dispatch(Actions.closeModal('addEditProductCandidateModal'));
          dispatch(Actions.validateIntersections());
          dispatch(Actions.validateTemplateHasElements());
        }
      } catch {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            addEditProductCandidateModal: {
              ...prevState.addEditProductCandidateModal,
              didTryToSave: false,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeUpdating: false,
            },
          })),
        );
      }
    };
  }

  public static openAdditionalTasksModal() {
    return (dispatch, getState: () => AppState) => {
      const { workflowTaskTemplates } = getState().workflow_editor;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          additionalTaskModal: {
            ...prev.additionalTaskModal,
            data: workflowTaskTemplates,
            isOpen: true,
          },
        })),
      );
    };
  }

  public static addTemporaryAdditionalTask() {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { data } = getState().workflow_editor.additionalTaskModal;
      const { id } = getState().workflow_editor.workflow;

      const workflowAdditionalTaskTemporaryId = `new-${uuidV4()}`;

      const temporaryAdditionalTask: WorkflowTaskTemplate = {
        id: workflowAdditionalTaskTemporaryId,
        workflow_template_id: id,
        name: '',
        priority: 'Medium',
        is_additional: true,
        time_limit: 0,
        order: data.length,
        description: 'Additional Task',
        is_control: false,
        taskTemplateResponsibilityCount: 1,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          additionalTaskModal: {
            ...prev.additionalTaskModal,
            data: [temporaryAdditionalTask, ...data],
          },
        })),
      );
    };
  }

  public static changeAdditionalTaskData(id: string, additionalTaskData: Partial<WorkflowTaskTemplate>) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;

      const mapped = data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...additionalTaskData,
          };
        }
        return item;
      });
      await dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplates: mapped,
          additionalTaskModal: {
            ...prev.additionalTaskModal,
            data: mapped,
          },
        })),
      );
    };
  }

  public static createAdditionalTask(temporaryId: string, name: string) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;
      const { productId } = getState().workflow_editor.workflow;

      const temporaryAdditionalTask = data.find((workflow) => workflow.id === temporaryId);

      const additionalTask = await WorkflowTaskTemplatesService.create({
        ...temporaryAdditionalTask,
        name,
      });
      dispatch(Actions.changeAdditionalTaskData(temporaryId, additionalTask));
      await dispatch(Actions.updateTasksIndicators());
      await ProductsService.updateProduct(productId);
      dispatch(Actions.validateTemplateHasElements());
    };
  }

  public static updateAdditionalTask(id: string, updates: Partial<WorkflowTaskTemplate>) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;
      const { productId } = getState().workflow_editor.workflow;

      const currentAdditionalTask = data.find((workflow) => workflow.id === id);

      try {
        dispatch(Actions.changeAdditionalTaskData(id, updates));
        const updatedFields = {
          ...currentAdditionalTask,
          ...updates,
        };
        Object.keys(updates).forEach((key) => {
          updatedFields[key] = updates[key];
        });

        await WorkflowTaskTemplatesService.update(id, updatedFields);
        await ProductsService.updateProduct(productId);
      } catch (error) {
        const revertUpdates = {};
        Object.keys(updates).forEach((key) => {
          revertUpdates[key] = currentAdditionalTask[key];
        });

        await dispatch(Actions.changeAdditionalTaskData(id, revertUpdates));
      }
    };
  }

  public static deleteTemporaryAdditionalTask(temporaryId: string) {
    return (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;

      const filteredAdditionalTasks = data.filter((item) => item.id !== temporaryId);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          additionalTaskModal: {
            ...prev.additionalTaskModal,
            data: filteredAdditionalTasks,
          },
        })),
      );
      dispatch(Actions.validateTemplateHasElements());
    };
  }

  public static deleteAdditionalTaskFromList(taskId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;
      const { productId } = getState().workflow_editor.workflow;

      try {
        dispatch(Actions.onSetTasks(data.filter((item) => item.id !== taskId)));
        await WorkflowTaskTemplatesService.delete(taskId);
        await ProductsService.updateProduct(productId);
        await dispatch(Actions.updateTasksIndicators());
        notify.success('Deleted successfully');
      } catch (error) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflowTaskTemplates: data,
            additionalTaskModal: {
              ...prevState.additionalTaskModal,
              data,
            },
          })),
        );
      }
      dispatch(Actions.validateTemplateHasElements());
    };
  }

  public static duplicateAdditionalTask(id: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { data } = getState().workflow_editor.additionalTaskModal;
      const { productId } = getState().workflow_editor.workflow;

      try {
        const newAdditionalTask = await WorkflowTaskTemplatesService.duplicate(id);
        const selectedIndex = data.findIndex((task) => task.id === id);

        if (selectedIndex !== -1) {
          dispatch(
            Actions.onSetTasks([
              ...data.slice(0, selectedIndex + 1),
              { ...newAdditionalTask, taskTemplateResponsibilityCount: newAdditionalTask.taskTemplateResponsibility.length },
              ...data.slice(selectedIndex + 1),
            ]),
          );
          await dispatch(Actions.updateTasksIndicators());
          await ProductsService.updateProduct(productId);
          notify.success('Task is duplicated');
        }
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplates: data,
            additionalTaskModal: {
              ...prev.additionalTaskModal,
              data,
            },
          })),
        );
      }
    };
  }

  public static copyTaskLink(nodeItem: WorkflowNode | TaskNode) {
    return async () => {
      const taskId = nodeItem.data.id;
      const currentDomain = window.location.hostname;
      const link = `https://${currentDomain}/task-template/${taskId}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link copied');
    };
  }

  public static copyAdditionalTaskLink(id: string) {
    return async () => {
      const currentDomain = window.location.hostname;
      const link = `https://${currentDomain}/task-template/${id}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link copied');
    };
  }

  public static copyProductLink(nodeItem: TaskNode | WorkflowNode) {
    return async () => {
      const productDraftId = nodeItem.data.product.id;
      const productVersionId = nodeItem.data.version.id;
      const configurationId = nodeItem.data.configuration.id;
      const currentDomain = window.location.hostname;
      const link = `https://${currentDomain}/product/${productDraftId}?product_preview=${productVersionId}&configuration=${configurationId}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link copied');
    };
  }

  public static onSetTasks(workflowTaskTemplates: WorkflowTaskTemplate[]) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.additionalTaskModal;
      let updatedWorkflowTaskTemplates = [...workflowTaskTemplates];
      const outOfOrder = updatedWorkflowTaskTemplates.some((item, index) => item.order !== index);
      if (outOfOrder) {
        updatedWorkflowTaskTemplates = updatedWorkflowTaskTemplates.map((item, index) => ({
          ...item,
          order: index,
        }));
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          additionalTaskModal: {
            ...prev.additionalTaskModal,
            data: updatedWorkflowTaskTemplates,
          },
          workflowTaskTemplates: updatedWorkflowTaskTemplates,
        })),
      );

      const manageOrder = updatedWorkflowTaskTemplates.map((item) => ({
        id: item.id,
        order: item.order,
      }));

      try {
        await WorkflowTaskTemplatesService.manageOrder(manageOrder);
      } catch (err) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplates: data,
            additionalTaskModal: {
              ...prev.additionalTaskModal,
              data,
            },
          })),
        );
      }
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  // Product Candidate Modal
  public static initAddProductCandidateModal() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: true,
            },
          })),
        );
        const { data, meta } = await ProductsService.getProductWithVersion();

        const productData: ProductDataWithProductVersions[] = data.map((item) => ({
          id: item.id,
          name: item.name,
          is_active: item.is_active,
          breadcrumbs: item.category_path,
          versions: item.published_products.map((prod: IdVersion) => {
            return {
              id: prod.id,
              name: versionName(prod.version),
              is_active: prod.is_active,
            };
          }),
          icon: <Products2Icon />,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              productData,
              pagination: {
                total: meta.total,
                currentPage: meta.currentPage,
                perPage: meta.perPage,
                lastPage: meta.lastPage,
                next: meta.next,
              },
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchProductsWithVersions(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      const { pagination } = getState().workflow_editor.addEditProductCandidateModal;

      if (isScroll && pagination.currentPage >= pagination.lastPage) return;

      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              pagination: {
                ...prev.addEditProductCandidateModal.pagination,
                next: 0,
              },
            },
          })),
        );
      }

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await ProductsService.getProductWithVersion(value.trim(), pagination.next, pagination.perPage);

          const productData: ProductDataWithProductVersions[] = data.map((item) => ({
            id: item.id,
            name: item.name,
            is_active: item.is_active,
            breadcrumbs: item.category_path,
            versions: item.published_products.map((prod: IdVersion) => {
              return {
                id: prod.id,
                name: versionName(prod.version),
                is_active: prod.is_active,
              };
            }),
            icon: <Products2Icon />,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              addEditProductCandidateModal: {
                ...prev.addEditProductCandidateModal,
                productData: isScroll ? [...prev.addEditProductCandidateModal.productData, ...productData] : productData,
                pagination: {
                  ...prev.addEditProductCandidateModal.pagination,
                  total: meta.total,
                  currentPage: meta.currentPage,
                  perPage: meta.perPage,
                  lastPage: meta.lastPage,
                  next: meta.next,
                },
                isLoading: false,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initEditProductCandidateModal() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: true,
            },
          })),
        );

        const { initProductDataForEdit } = getState().workflow_editor.addEditProductCandidateModal;
        const configurationId = initProductDataForEdit.data.configuration.id;
        const { data } = await ProductsService.getProductWithVersion();
        const productConfigurations = await ProductConfigurationService.getAllByProductId(initProductDataForEdit.data.version.id);

        const mappedProducts = data.map((item) => ({
          id: item.id,
          name: item.name,
          is_active: item.is_active,
          breadcrumbs: item.category_path,
          icon: <Products2Icon />,
          versions: item.published_products.map((prod: IdVersion) => {
            return {
              id: prod.id,
              name: versionName(prod.version),
              is_active: prod.is_active,
            };
          }),
        }));
        const mappedConfigurations = productConfigurations.map((item) => {
          item.workflowTemplates.push({
            id: 'null',
            is_active: true,
            name: 'Undefined',
            order: 2147483647,
            product_configuration_id: item.id,
            is_valid: true,
            tasks_count: 0,
            products_count: 0,
          });
          return {
            id: item.id,
            name: item.name,
            is_active: item.is_active,
            workflowTemplates: item.workflowTemplates,
          };
        });
        const mappedWorkflowTemplates = productConfigurations.find((i) => i.id === configurationId)?.workflowTemplates;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              productData: mappedProducts,
              productConfigurationData: mappedConfigurations,
              productPosition: {
                x: initProductDataForEdit.position.x,
                y: initProductDataForEdit.position.y,
              },
              workflowTemplates: mappedWorkflowTemplates,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static updateData(dataItem: IdNameIsActive | null, propertyToUpdate: string) {
    return (dispatch, getState: () => AppState) => {
      const configurations = getState().workflow_editor.addEditProductCandidateModal.productConfigurationData;
      const newVariants = configurations.find((item) => item.id === dataItem.id)?.workflowTemplates || [];

      dispatch(
        stateController.setState((prev) => {
          const newData = { ...prev.addEditProductCandidateModal.data };
          const [newVariant] = newVariants;
          switch (propertyToUpdate) {
            case 'product':
              newData.product = dataItem;
              break;
            case 'configuration':
              newData.configuration = dataItem;
              newData.variant = newVariant;
              break;
            default:
              break;
          }
          return {
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              data: newData,
              workflowTemplates: newVariants,
            },
          };
        }),
      );
    };
  }

  public static updateVersion(data: (IdNameIsActive & { is_new_version_available: boolean }) | null) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => {
          const newData = { ...prev.addEditProductCandidateModal.data };
          newData.version = data;
          return {
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              data: newData,
            },
          };
        }),
      );
    };
  }

  public static toggleTaskAutoAssignment() {
    return async (dispatch, getState: () => AppState) => {
      const { taskAutoAssignmentEnabled, workflow } = getState().workflow_editor;

      try {
        const response = await WorkflowTemplatesService.update(workflow.id, {
          prefer_auto_assign_users_with_prior_experience: !taskAutoAssignmentEnabled,
        });

        await ProductsService.updateProduct(workflow.productId);

        dispatch(
          stateController.setState({ taskAutoAssignmentEnabled: response.prefer_auto_assign_users_with_prior_experience }),
        );
      } catch (error) {
        notify.error(error);
      }
    };
  }

  public static updateVariant(data: (IdNameIsActive & { is_nested_workflow_template_selected: boolean }) | null) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => {
          const newData = { ...prev.addEditProductCandidateModal.data };
          newData.variant = data;
          return {
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              data: newData,
            },
          };
        }),
      );
    };
  }

  public static loadProductConfigurations() {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().workflow_editor.addEditProductCandidateModal;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoadingConfiguration: true,
            },
          })),
        );
        let productConfiguration: ProductConfiguration[] = [];

        if (data.version.id) {
          productConfiguration = await ProductConfigurationService.getAllByProductId(data.version.id);
        }

        const productConfigurationData = productConfiguration.map((item) => {
          item.workflowTemplates.push({
            id: 'null',
            is_active: true,
            name: 'Undefined',
            order: 2147483647,
            product_configuration_id: item.id,
            is_valid: true,
            tasks_count: 0,
            products_count: 0,
          });
          return {
            id: item.id,
            name: item.name,
            is_active: item.is_active,
            workflowTemplates: item.workflowTemplates,
          };
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              productConfigurationData,
            },
          })),
        );
        return productConfigurationData;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addEditProductCandidateModal: {
              ...prev.addEditProductCandidateModal,
              isLoadingConfiguration: false,
            },
          })),
        );
      }
    };
  }

  public static openAddProductCandidateModal(position) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addEditProductCandidateModal: {
            ...prev.addEditProductCandidateModal,
            isOpen: true,
            productPosition: position,
          },
        })),
      );
    };
  }

  public static openEditProductCandidateModal(workflowNode: WorkflowNode) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addEditProductCandidateModal: {
            ...prev.addEditProductCandidateModal,
            isOpen: true,
            isEdit: true,
            initProductDataForEdit: workflowNode,
          },
        })),
      );
    };
  }

  public static onProductFieldChange(value: Partial<ProductCandidate>) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(
        stateController.setState((prevState) => {
          const newState = {
            ...prevState,
            addEditProductCandidateModal: {
              ...prevState.addEditProductCandidateModal,
              data: {
                ...prevState.addEditProductCandidateModal.data,
                ...value,
              },
            },
          };
          return newState;
        }),
      );
      if (getState().workflow_editor.addEditProductCandidateModal.didTryToSave) {
        dispatch(Actions.productDataValidation());
      }
    };
  }

  public static productDataValidation() {
    return (dispatch, getState: () => AppState) => {
      const { productDataValidationText, data } = getState().workflow_editor.addEditProductCandidateModal;
      const productDataValidatedText = {};

      Object.keys(productDataValidationText).forEach((key) => {
        const [, errorMessage] = validators.isNotEmpty(data[key], `Поле ${key} Не повинно бути порожнім`);
        productDataValidatedText[key] = errorMessage;
      });
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          addEditProductCandidateModal: {
            ...prevState.addEditProductCandidateModal,
            productDataValidationText: productDataValidatedText,
          },
        })),
      );
    };
  }

  public static openDeleteConfirmationModal(taskId) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('additional_tasks.delete_additional_task_modal.title')}</>,
            text: (
              <Trans i18nKey="additional_tasks.delete_additional_task_modal.text">
                <div style={{ marginBottom: '7px' }}>Are you sure you want to delete the task?</div>,
              </Trans>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteAdditionalTaskFromList(taskId)),
          },
        }),
      );
    };
  }

  public static openDeleteWorkflowItemConfirmationModal(nodeItems: WorkflowNode[] | TaskNode[] | Node<NodeData>[]) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const ids = nodeItems.map((i) => i.id);

      const sortedNodeItems = nodeItems.sort((a, b) => {
        const typeOrder = { task: 1, workflow: 2 };
        return typeOrder[a.type] - typeOrder[b.type];
      });

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete items?',
            text: (
              <div>
                Are you sure you want to delete following items?
                <ul>
                  {sortedNodeItems.map((item) => {
                    const itemType = item.type === 'task' ? 'Task' : 'Component';

                    return (
                      <li key={item.id} style={{ listStyleType: 'disc', marginLeft: 25 }}>
                        <span>{itemType}</span>: <strong>{item.data.name}</strong>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => {
              dispatch(Actions.deleteWorkflowItems(ids));
            },
          },
        }),
      );
    };
  }

  public static deleteWorkflowItems(ids: string[]) {
    return async (dispatch, getState: () => AppState) => {
      const { productId } = getState().workflow_editor.workflow;

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeDeleting: true,
              deletingItems: [...prevState.workflow.deletingItems, ...ids],
            },
          })),
        );
        await WorkflowTemplateItemsService.deleteMultiple({ Ids: [...ids] });
        await ProductsService.updateProduct(productId);
        await dispatch(Actions.updateTasksIndicators());

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              nodes: prevState.workflow.nodes.filter((item) => !ids.includes(item.id)),
            },
          })),
        );
        dispatch(Actions.validateIntersections());
        dispatch(Actions.validateTemplateHasElements());
        notify.success('Deleted successfully');
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              isNodeDeleting: false,
              deletingItems: prevState.workflow.deletingItems.filter((item) => !ids.includes(item)),
            },
            taskCandidateOrEdit: null,
          })),
        );
      }
    };
  }

  public static onNodesChange(newNodes: Node<NodeData>[], nodeChanged: nodeChangedData[]) {
    return async (dispatch, getState: () => AppState) => {
      // eslint-disable-next-line no-param-reassign
      Object.values(newNodes).forEach((node) => delete node.dragging);

      const {
        updatePositionTimeout: timeoutToClear,
        changedPositionNodeId: prevNodeId,
        id: workflowId,
        nodes,
      } = getState().workflow_editor.workflow;

      try {
        let timeout;
        let newNodeId;

        if (nodes.length !== newNodes.length) {
          const deletedNodes = nodes.filter((node) => !newNodes.includes(node));

          dispatch(Actions.openDeleteWorkflowItemConfirmationModal(deletedNodes));
        } else if (nodeChanged.length === 1 && nodeChanged[0].type === 'position') {
          const node = newNodes.find((item) => item.id === nodeChanged[0].id);
          const nested_workflow_template_id = node.type === 'task' ? null : node.data.id;
          const is_nested_workflow_template_selected =
            node.type === 'task' ? true : node.data.variant.is_nested_workflow_template_selected;
          const workflow_task_template_id = node.type === 'task' ? node.data.id : null;
          newNodeId = node.id;

          if (prevNodeId === newNodeId) {
            clearTimeout(timeoutToClear);
          }

          timeout = setTimeout(async () => {
            await WorkflowTemplateItemsService.update(node.id, {
              nested_workflow_template_id,
              is_nested_workflow_template_selected,
              workflow_task_template_id,
              workflow_template_id: workflowId,
              position_x: node.position.x,
              position_y: node.position.y,
            });
          }, 1000);
        } else if (nodeChanged.length !== 1 && nodeChanged[0].type === 'position') {
          const body = nodeChanged.map((item) => ({
            id: item.id,
            position_x: item.position.x,
            position_y: item.position.y,
          }));

          timeout = setTimeout(async () => {
            await WorkflowTemplateItemsService.updateMultiple(body);
          }, 1000);
        }

        if (nodes.length === newNodes.length) {
          dispatch(
            stateController.setState((prevState) => ({
              ...prevState,
              workflow: {
                ...prevState.workflow,
                nodes: newNodes,
                updatePositionTimeout: timeout,
                changedPositionNodeId: newNodeId,
              },
            })),
          );
        }
      } catch {
        clearTimeout(timeoutToClear);
      } finally {
        dispatch(Actions.validateIntersections());
      }
    };
  }

  public static onConnectStart = () => {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isConnectionInProgress: true,
        })),
      );
    };
  };

  public static onConnectEnd = () => {
    return async (dispatch) => {
      setTimeout(() => {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isConnectionInProgress: false,
          })),
        );
      }, 500);
    };
  };

  public static onConnect = (connection: Connection) => {
    return async (dispatch, getState: () => AppState) => {
      const { source, sourceHandle, target, targetHandle } = connection;
      const { productId } = getState().workflow_editor.workflow;
      const { edges } = getState().workflow_editor.workflow;
      if (edges.find((e) => e.source === source && e.target === target)) {
        notify.error('Relation already exists');
        return;
      }
      if (source === target) {
        notify.error('Cycle relation error');
        return;
      }

      const tempEdgeId = `temporary-edge-id-${uuidV4()}`;
      const newTempEdge: Edge = {
        id: tempEdgeId,
        source,
        sourceHandle,
        target,
        targetHandle,
        type: 'smoothstep',
      };

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            edges: [...prevState.workflow.edges, newTempEdge],
          },
        })),
      );

      try {
        const { id } = await WorkflowEdgesService.create({
          source_id: source,
          target_id: target,
        });
        await ProductsService.updateProduct(productId);

        const newEdge: Edge = {
          id,
          source,
          sourceHandle,
          target,
          targetHandle,
          type: 'smoothstep',
        };

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              edges: [...prevState.workflow.edges, newEdge],
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              edges: [...prevState.workflow.edges.filter((e) => e.id !== tempEdgeId)],
            },
          })),
        );
      }
    };
  };

  public static onEdgesChange(newEdges: Edge[] | null, deselectOnly?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const selectedEdge = newEdges?.find((edge) => edge.selected);
      const isSelected = Boolean(getState().workflow_editor.workflow.selectedEdge);
      const { edges } = getState().workflow_editor.workflow;

      let updatedEdges: Edge[];

      if (deselectOnly && isSelected) {
        updatedEdges = edges.map((item) => ({
          ...item,
          selected: false,
        }));
      } else if (Array.isArray(newEdges) && !deselectOnly) {
        updatedEdges = newEdges;
      } else {
        updatedEdges = edges;
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            edges: updatedEdges,
            selectedEdge: selectedEdge || null,
          },
        })),
      );
    };
  }

  public static deleteEdge(id: string) {
    return async (dispatch, getState: () => AppState) => {
      if (id.includes('temporary-edge-id-')) {
        return;
      }
      const { productId, edges } = getState().workflow_editor.workflow;
      const edgeForDelete = edges.find((edge) => edge.id !== id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            edges: prevState.workflow.edges.filter((edge) => edge.id !== id),
            selectedEdge: null,
          },
        })),
      );
      try {
        await WorkflowEdgesService.delete(id);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              edges: prevState.workflow.edges.filter((edge) => edge.id !== id),
              selectedEdge: null,
            },
          })),
        );
        await ProductsService.updateProduct(productId);
      } catch {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            workflow: {
              ...prevState.workflow,
              edges: [...prevState.workflow.edges, edgeForDelete],
            },
          })),
        );
      }
    };
  }

  public static validateIntersections() {
    return (dispatch, getState: () => AppState) => {
      const { nodes } = getState().workflow_editor.workflow;

      const positionsCount = nodes.reduce((acc, node) => {
        const position = `${node.position.x}-${node.position.y}`;
        acc[position] = (acc[position] || 0) + 1;
        return acc;
      }, {});

      const nodesWithIntersectionWarnings = nodes.map((node) => {
        const position = `${node.position.x}-${node.position.y}`;
        const isOverlapped = positionsCount[position] > 1;
        return {
          ...node,
          data: {
            ...node.data,
            overlapWarning: isOverlapped,
            numberOfOverlappedItems: isOverlapped ? positionsCount[position] : 0,
          },
        };
      });

      const groupedNodes: { [key: string]: TaskNode[] | WorkflowNode[] } = {};
      const intersectionWarnings: string[] = [];

      if (nodesWithIntersectionWarnings.some((item) => item.data.overlapWarning)) {
        nodesWithIntersectionWarnings.forEach((item) => {
          const { overlapWarning } = item.data;
          const { position } = item;

          if (overlapWarning) {
            const key = `${position.x}_${position.y}`;
            if (groupedNodes[key]) {
              groupedNodes[key].push(item);
            } else {
              groupedNodes[key] = [item];
            }
          }
        });

        const findLatestObjectInGroup = (group: TaskNode[] | WorkflowNode[]) => {
          return group.reduce((latest, current) => {
            if (!latest || new Date(current.data.created_at) > new Date(latest.data.created_at)) {
              return current;
            }
            return latest;
          }, null);
        };

        Object.values(groupedNodes).forEach((group) => {
          const latestObject = findLatestObjectInGroup(group);
          if (latestObject) {
            let slicedName = latestObject.data.name;
            if (slicedName.length > 17) {
              slicedName = `${slicedName.slice(0, 17)}...`;
            }

            const resultString = `${latestObject.data.numberOfOverlappedItems} items intersected on the ${latestObject.type} "${slicedName}"`;
            intersectionWarnings.push(resultString);
          }
        });
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            nodes: nodesWithIntersectionWarnings,
            validation: {
              ...prevState.workflow.validation,
              intersection: [...intersectionWarnings],
            },
          },
        })),
      );
    };
  }

  public static validateTemplateHasElements() {
    return async (dispatch, getState: () => AppState) => {
      const { nodes } = getState().workflow_editor.workflow;
      const additionalTasks = getState().workflow_editor.workflowTaskTemplates;

      const validationMessage =
        nodes.length || additionalTasks.length ? [] : ['No workflow or additional tasks in this template'];

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            validation: {
              ...prevState.workflow.validation,
              hasElements: validationMessage,
            },
          },
        })),
      );
    };
  }

  public static setIsFullScreen(value: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          workflow: {
            ...prevState.workflow,
            isFullScreen: value,
          },
        })),
      );
    };
  }

  public static changeIsActive(id: string, is_active: boolean) {
    return async (dispatch) => {
      try {
        stateController.setState((prev) => ({
          ...prev,
          workflow: {
            ...prev.workflow,
            isLoading: true,
          },
        }));
        await WorkflowTemplatesService.update(id, { is_active });
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow: {
              ...prev.workflow,
              is_active,
            },
          })),
        );
        dispatch(Actions.closeModal('changeIsActiveModal'));
      } finally {
        stateController.setState((prev) => ({
          ...prev,
          workflow: {
            ...prev.workflow,
            isLoading: false,
          },
        }));
      }
    };
  }

  public static openChangeIsActiveConfirmationModal(wokrflowTemplateId: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { name: productName } = getState().product.product_root.product_meta;
      const { name: workflowName, productId, versions } = getState().workflow_editor.workflow;
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: is_active ? 'Activate workflow template?' : 'Inactivate workflow template?',
            text: is_active ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will allow this
                  workflow to be selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(
                        ProductConfigurationController.openRelationProductModal(
                          productId,
                          productName,
                          versions[0]?.version.replace(/\D/g, ''),
                        ),
                      );
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{workflowName}</strong>?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will prevent this
                  workflow from being selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(
                        ProductConfigurationController.openRelationProductModal(
                          productId,
                          productName,
                          versions[0]?.version.replace(/\D/g, ''),
                        ),
                      );
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{workflowName}</strong> of the product?
                </span>
              </div>
            ),
            icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
            backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
            withCloseButton: false,
            actionText: is_active ? 'Activate' : 'Inactivate',
            actionButtonColor: is_active ? 'primary' : 'error',
            action: () => dispatch(Actions.changeIsActive(wokrflowTemplateId, is_active)),
          },
        }),
      );
    };
  }
}

export class Selectors {
  public static idNodeToEdit(state: AppState) {
    return state.workflow_editor.taskCandidateOrEdit?.id;
  }
}

export const reducer = stateController.getReducer();
