import { StateController } from 'state-controller';
import { Edge, Node } from 'reactflow';
import { BreadcrumbItem, ItemType } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import {
  ProductionWorkflowInfo,
  ProductionWorkflowResponsibleT,
  ProductionWorkflowTaskResponsibility,
  ProductionWorkflowUpdateBody,
} from 'services/production-workflow.model';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { AppState } from 'redux/store';
import { CancelProductionActions } from 'pages/production/controllers/cancel-production-modal.controller';
import { HIGHLIGHTED_SLOT } from 'pages/production/controllers/constants';
import { Actions as ProductionAdditionalTasksActions } from 'pages/production-workflow/controllers/production-workflow-additional-tasks.controller';
import { ProductionTaskService } from 'services/production-task.service';
import { User, UserShortModel } from 'services/user.model';
import { UserService } from 'services/user.service';
import { TaskAssigmentService } from 'services/task-assigment.service';
import { TaskSuggestedUsersModel } from 'services/task-responsibility.model';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { ProductsLaunchModalActions } from 'pages/production/controllers/products-launch-modal-controller/products-launch-modal.controller';
import { generateNodes, getAllIssues, getTasksIssues } from 'pages/production-workflow/controllers/helpers';
import { IdName } from 'types/common-types';
import { StopProductionActions } from 'pages/production/controllers/stop-production-modal.controller';
import { ProductionListActions } from 'pages/production/controllers/production-list-controller/production-list.controller';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { PriorityEnum } from 'types/priority-enums';
import {
  HandleProductionStatusArgs,
  SilentLoadProductionWorkflowArgs,
  UpdateProductionStatusArgs,
} from 'pages/production-workflow/controllers/types';
import { AdditionalComponentsActions } from 'pages/production-workflow/controllers/production-workflow-additional-components.controller';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { router } from '../../../index';
import { notify } from '../../../notifications';

export type TaskData = {
  id: string;
  name: string;
  disable: boolean;
  issues: IdName[];
  time_limit: number;
  finishedAt: string;
  isReopened: boolean;
  is_in_queue: boolean;
  status: TaskStatusEnum;
  departmetsAll?: IdName[];
  total_in_progress_time: number;
  is_reporting_period_closed: boolean;
  productionStatus: ProductionStatusEnum;
  responsibilities: ProductionWorkflowTaskResponsibility[];
};

export type NestedWorkflowData = {
  id: string;
  name: string;
  version: number;
  workflow: string;
  progress: number;
  issues: IdName[];
  finishedAt: string;
  wasChanged: boolean;
  variantName: string;
  configuration: string;
  productVariantId: string;
  isRootCompleted: boolean;
  status: ProductionStatusEnum;
  productionStatus: ProductionStatusEnum;
  responsible: ProductionWorkflowResponsibleT;
};

export type DeadlineUpdateBody = {
  day: number;
  year: number;
  hours: number;
  month: number;
  minutes: number;
};

export type TaskNodeType = Node<TaskData, 'task'>;
export type WorkflowNodeType = Node<NestedWorkflowData, 'workflow'>;

export type Nodes = Array<TaskNodeType | WorkflowNodeType>;

export type ProductionWorkflowState = {
  nodes: Nodes;
  edges: Edge[];
  users: User[];
  isLoading: boolean;
  allIssues: string[];
  isFullScreen: boolean;
  highlightedSlotId: string;
  breadcrumbs: BreadcrumbItem[];
  isLoadingUsersWithSuggested: boolean;
  taskAutoAssignmentEnabled: boolean;
  productionInfo: ProductionWorkflowInfo;
  usersWithSuggested: TaskSuggestedUsersModel;
};

const defaultState: ProductionWorkflowState = {
  nodes: [],
  edges: [],
  users: [],
  allIssues: [],
  breadcrumbs: [],
  isLoading: true,
  isFullScreen: false,
  highlightedSlotId: '',
  isLoadingUsersWithSuggested: false,
  taskAutoAssignmentEnabled: false,
  usersWithSuggested: {
    otherUsers: [],
    suggestedUsers: [],
  },
  productionInfo: {
    id: '',
    edges: [],
    notes: [],
    title: '',
    issues: [],
    version: 0,
    vendor: '',
    progress: 0,
    created_at: '',
    started_at: '',
    breadcrumbs: [],
    deadline_at: '',
    finished_at: '',
    product_name: '',
    product_type: '',
    production_key: '',
    is_external: false,
    variantsHistory: [],
    additionalTasks: [],
    deadlineHistory: [],
    product_meta_id: '',
    is_additional: false,
    additionalComponents: [],
    additionalTasksProgress: 0,
    parent_product_meta_ids: [],
    productionWorkflowItems: [],
    is_launch_in_progress: false,
    externalOrderNumberHistory: [],
    additionalComponentsProgress: 0,
    priority: PriorityEnum.Medium,
    is_root_workflow_completed: false,
    status: ProductionStatusEnum.To_Do,
    variant: { id: '', name: '', sku: '' },
    configuration: { id: '', name: '', sku: '' },
    main_workflow_status: ProductionStatusEnum.To_Do,
    responsible: {
      id: '',
      last_name: '',
      first_name: '',
      avatar_image_url: '',
      user_position_slots: [],
    },
    order: {
      id: '',
      order_key: '',
      client_name: '',
      external_order_id: '',
      external_product_id: '',
      external_system_name: '',
      external_order_number: '',
      marketplace_order_number: '',
      priority: PriorityEnum.Medium,
      client: {
        id: '',
        name: '',
        email: '',
        phone: '',
        company: '',
        external_client_id: '',
      },
    },
  },
};

const stateController = new StateController<ProductionWorkflowState>('PRODUCTION_WORKFLOW', defaultState);

export class Actions {
  public static initWorkflowPage(id: string) {
    return async (dispatch) => {
      try {
        const [data, users] = await Promise.all([
          ProductionWorkflowService.getProductionWorkflowInfo(id),
          UserService.getAllUsers({ skip: 0, take: PAGE_SIZE }),
        ]);

        const nodes = data.productionWorkflowItems.flatMap((item) =>
          generateNodes(item, data.status, data.is_root_workflow_completed),
        );
        const additionalTaskIssues = [];
        const additionalTaskWithIssues = data.additionalTasks.map((task) => {
          const taskIssues = getTasksIssues(task);
          if (taskIssues.length) {
            additionalTaskIssues.push(...taskIssues.map((issue) => getAllIssues(issue, task.name, 'task_additional')));
          }
          return {
            ...task,
            issues: taskIssues,
          };
        });
        const allIssues = nodes
          .filter((node) => node.data.issues.length)
          .flatMap((node) => node.data.issues.map((issue) => getAllIssues(issue, node.data.name, node.type)));
        allIssues.push(...additionalTaskIssues);

        dispatch(ProductionAdditionalTasksActions.onChange({ tasks: additionalTaskWithIssues }));
        const highlightedSlotId = sessionStorage.getItem(HIGHLIGHTED_SLOT);

        dispatch(
          stateController.setState((prevState) => {
            return {
              ...prevState,
              users: users.data,
              allIssues,
              name: data.title,
              highlightedSlotId,
              taskAutoAssignmentEnabled: data.prefer_auto_assign_users_with_prior_experience,
              productionInfo: data,
              breadcrumbs: [
                {
                  type: 'production',
                  name: 'Production',
                },
                ...data.breadcrumbs.map((breadcrumb) => ({
                  ...breadcrumb,
                  isAllowedCopy: true,
                  isHaveTooltip: true,
                  isDisabled: breadcrumb.type === ItemType.OrderKey && true,
                })),
              ] as BreadcrumbItem[],
              edges: data.edges.map((edge) => ({
                id: edge.id,
                source: edge.source_id,
                target: edge.target_id,
                type: 'smart',
              })),
              nodes,
            };
          }),
        );
      } finally {
        sessionStorage.removeItem(HIGHLIGHTED_SLOT);
        dispatch(stateController.setState((prevState) => ({ ...prevState, isLoading: false })));
      }
    };
  }

  public static silentLoad({ id, disableAdditionalTasksSet }: SilentLoadProductionWorkflowArgs) {
    return async (dispatch) => {
      const data = await ProductionWorkflowService.getProductionWorkflowInfo(id);

      const nodes = data.productionWorkflowItems.flatMap((item) =>
        generateNodes(item, data.status, data.is_root_workflow_completed),
      );
      const additionalTaskIssues = [];
      const additionalTaskWithIssues = data.additionalTasks.map((task) => {
        const taskIssues = getTasksIssues(task);
        if (taskIssues.length) {
          additionalTaskIssues.push(...taskIssues.map((issue) => getAllIssues(issue, task.name, 'task_additional')));
        }
        return {
          ...task,
          issues: taskIssues,
        };
      });
      const allIssues = nodes
        .filter((node) => node.data.issues.length)
        .flatMap((node) => node.data.issues.map((issue) => getAllIssues(issue, node.data.name, node.type)));
      allIssues.push(...additionalTaskIssues);

      if (!disableAdditionalTasksSet) dispatch(ProductionAdditionalTasksActions.onChange({ tasks: additionalTaskWithIssues }));

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          productionInfo: data,
          allIssues,
          nodes,
        })),
      );
    };
  }

  public static handleProductionStatus = ({
    status,
    production,
    updatingStatusFrom,
    productionIdToUpdate,
    productionIdToLoadDataFor,
  }: HandleProductionStatusArgs) => {
    return async (dispatch, getState: () => AppState) => {
      const productionInfo = production || getState().production_workflow.productionInfo;

      const updateProduction = () => {
        if (updatingStatusFrom === LocationTheProductionStatusIsChangingFrom.WorkflowItemAdditionalProduction) {
          return dispatch(AdditionalComponentsActions.updateProduction(productionIdToUpdate, { status }));
        }

        if (production) {
          return dispatch(ProductionListActions.updateProduction(productionIdToUpdate, { status }, false));
        }

        return dispatch(Actions.updateProduction(productionIdToUpdate, { status }));
      };

      if (
        !(await dispatch(
          Actions.updateProductionStatus({
            status,
            productionIdToLoadDataFor,
            location: updatingStatusFrom,
            productionInfo: {
              id: productionIdToUpdate,
              title: productionInfo.title,
              status: productionInfo.status,
              version: productionInfo.version,
              variantId: productionInfo.variant.id,
              variantName: productionInfo.variant.name,
              configurationName: productionInfo.configuration.name,
            },
          }),
        ))
      ) {
        updateProduction();
      }
    };
  };

  public static updateProductionStatus = ({
    status,
    location,
    productionInfo,
    productionIdToLoadDataFor,
  }: UpdateProductionStatusArgs) => {
    return async (dispatch) => {
      if (
        (productionInfo.status === ProductionStatusEnum.To_Do || productionInfo.status === ProductionStatusEnum.From_Stock) &&
        status === ProductionStatusEnum.In_Progress
      ) {
        dispatch(
          ProductsLaunchModalActions.openModal({
            openedFrom: location,
            productionIdToLoadDataFor,
            version: productionInfo.version,
            variantName: productionInfo.variantName,
            productionIdToLaunch: productionInfo.id,
            productVariantId: productionInfo.variantId,
            configurationName: productionInfo.configurationName,
          }),
        );
        return true;
      }

      if (productionInfo.status === ProductionStatusEnum.In_Progress && status === ProductionStatusEnum.Stopped) {
        dispatch(StopProductionActions.openModal(productionInfo.id, productionInfo.title, location));
        return true;
      }

      if (
        (productionInfo.status === ProductionStatusEnum.In_Progress || productionInfo.status === ProductionStatusEnum.Stopped) &&
        status === ProductionStatusEnum.Canceled
      ) {
        const canCancel = await ProductionWorkflowService.getCanCancelProduction(productionInfo.id);

        dispatch(
          CancelProductionActions.openModal({
            page: location,
            productionIdToLoadDataFor,
            tasks: canCancel.tasks ?? [],
            productionId: productionInfo.id,
            productionName: productionInfo.title,
            components: canCancel.components ?? [],
          }),
        );

        return true;
      }

      return false;
    };
  };

  public static updateProduction(id: string, value: Partial<ProductionWorkflowInfo>) {
    return async (dispatch, getState: () => AppState) => {
      const { productionInfo } = getState().production_workflow;

      const updateData: ProductionWorkflowUpdateBody = {
        status: value.status,
        priority: value.priority,
        responsible_id: value.responsible?.id || null,
      };

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionInfo: { ...prev.productionInfo, ...value },
          })),
        );

        await ProductionWorkflowService.update(id, updateData);
        dispatch(Actions.silentLoad({ id: productionInfo.id, disableAdditionalTasksSet: true }));
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, productionInfo })));
        notify.error(error);
      }
    };
  }

  public static toggleTaskAutoAssignment() {
    return async (dispatch, getState: () => AppState) => {
      const { productionInfo, taskAutoAssignmentEnabled } = getState().production_workflow;

      try {
        const response = await ProductionWorkflowService.update(productionInfo.id, {
          prefer_auto_assign_users_with_prior_experience: !taskAutoAssignmentEnabled,
        });

        dispatch(
          stateController.setState({ taskAutoAssignmentEnabled: response.prefer_auto_assign_users_with_prior_experience }),
        );
      } catch (error) {
        notify.error(error);
      }
    };
  }

  public static updateCanvasNodes(id: string, value: Partial<TaskData | NestedWorkflowData>, type: 'workflow' | 'task') {
    return async (dispatch, getState: () => AppState) => {
      const { nodes, productionInfo } = getState().production_workflow;

      try {
        if (type === 'workflow') {
          const { data: oldWorkflow } = nodes.find((item) => item.data.id === id);
          const isProductionStatusUpdated = await dispatch(
            Actions.updateProductionStatus({
              location: LocationTheProductionStatusIsChangingFrom.WorkflowItem,
              productionIdToLoadDataFor: productionInfo.id,
              status: value.status as ProductionStatusEnum,
              productionInfo: {
                id,
                title: oldWorkflow.name,
                status: oldWorkflow.status as ProductionStatusEnum,
                version: (oldWorkflow as NestedWorkflowData).version,
                variantName: (oldWorkflow as NestedWorkflowData).variantName,
                variantId: (oldWorkflow as NestedWorkflowData).productVariantId,
                configurationName: (oldWorkflow as NestedWorkflowData).configuration,
              },
            }),
          );

          if (!isProductionStatusUpdated) {
            dispatch(
              stateController.setState((prev) => ({
                ...prev,
                nodes: prev.nodes.map((node) => (node.data.id === id ? { ...node, data: { ...node.data, ...value } } : node)),
              })),
            );
            await ProductionWorkflowService.update(id, {
              status: value.status,
              responsible_id: (value as NestedWorkflowData).responsible?.id || null,
            });
            if (value.status) await dispatch(Actions.silentLoad({ id: productionInfo.id }));
          }
        }
        if (type === 'task') {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              nodes: prev.nodes.map((node) => (node.data.id === id ? { ...node, data: { ...node.data, ...value } } : node)),
            })),
          );

          await ProductionTaskService.updateTask(id, {
            status: value.status,
            is_in_queue: 'is_in_queue' in value ? value.is_in_queue : undefined,
            make_assignment: false,
          });
          await dispatch(Actions.silentLoad({ id: productionInfo.id }));
        }
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, nodes })));
        notify.error(error);
      }
    };
  }

  public static clearWorkflowPage() {
    return async (dispatch) => dispatch(stateController.setState(() => defaultState));
  }

  public static setIsFullScreen(value: boolean) {
    return (dispatch) => dispatch(stateController.setState((prevState) => ({ ...prevState, isFullScreen: value })));
  }

  public static deleteProductionWorkflow(id: string) {
    return async (dispatch) => {
      await ProductionWorkflowService.delete(id);
      dispatch(Actions.clearWorkflowPage());
      await router.navigate('/production');
      notify.success('Deleted production workflow successfully');
    };
  }

  public static handleTaskUses(taskId: string, user: UserShortModel, slotId: string, type: 'assign' | 'unassign') {
    return async (dispatch, getState: () => AppState) => {
      const isAssign = type === 'assign';
      const { nodes, productionInfo } = getState().production_workflow;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            nodes: prev.nodes.map((node: TaskNodeType) => {
              return node.data.id === taskId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      responsibilities: node.data.responsibilities.map((responsibility) => ({
                        ...responsibility,
                        taskSlots: responsibility.taskSlots.map((slot) => {
                          return slot.id === slotId
                            ? {
                                ...slot,
                                task_assignment_id: isAssign ? user.id : null,
                                taskAssignment: isAssign ? { user } : null,
                              }
                            : slot;
                        }),
                      })),
                    },
                  }
                : node;
            }),
          })),
        );
        if (isAssign) {
          await TaskAssigmentService.assignUser(user.id, slotId);
        } else {
          await TaskAssigmentService.unassignUser(slotId);
        }
        dispatch(Actions.silentLoad({ id: productionInfo.id }));
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, nodes })));
        notify.error('Somethin went wrong');
      }
    };
  }

  public static updateDeadline(id: string, body: DeadlineUpdateBody, page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { productionInfo } = getState().production_workflow;

        const { day, hours, minutes, month, year } = body;
        const date = new Date(year, month - 1, day, hours, minutes);

        const updateData: ProductionWorkflowUpdateBody = {
          deadline_at: date.toISOString(),
        };

        await ProductionWorkflowService.update(id, updateData);

        if (page === Page.InfoDropdownWorkflow) {
          await dispatch(Actions.silentLoad({ id: productionInfo.id }));
        } else if (page === Page.InfoDropdownProduction) {
          const { currentPage } = getState().production.filters.pagination;
          const { groupBy } = getState().production.filters;
          await dispatch(
            ProductionFiltersActions.getProductsByFilter({ currentPage, customGroupBy: groupBy, showFetchEffect: false }),
          );
        }
        notify.success('Successfully updated');
      } catch (err) {
        notify.error(err);
      }
    };
  }
}

export class Selectors {
  public static canUserManageFailedTasks(state: AppState) {
    const productionInfo = state.production_workflow?.productionInfo;
    if (productionInfo) {
      const completed_tasks_exists =
        state.production_workflow?.productionInfo?.productionWorkflowItems.some(
          (item) =>
            (item.task?.status === TaskStatusEnum.Done && !item.task?.reopened_tasks.length) ||
            item.task?.reopened_tasks?.some((rt) => rt.status === TaskStatusEnum.Done && !rt.failed_by),
        ) ?? false;
      const canUserManageFailedTasks =
        productionInfo.status !== ProductionStatusEnum.Canceled &&
        !productionInfo.is_root_workflow_completed &&
        completed_tasks_exists;
      return canUserManageFailedTasks;
    }
    return false;
  }

  public static checkIfProductionInMassLaunch(state: AppState) {
    return (
      !!state.production.productionList.launchingProductionIds.length ||
      state.production_workflow.productionInfo?.is_launch_in_progress
    );
  }
}

export const reducer = stateController.getReducer();
