import { FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { EnvCookies } from 'env-cookies';
import Select from 'components/ui-new/select/select';
import { Actions as AuthActions } from 'redux/auth.controller';
import { Tenant, TenantUserStatus } from 'services/tenant.model';
import { CompanyLogo } from 'components/company-logo/company-logo';
import s from './tenants.module.scss';

type OwnProps = {
  isOpen: boolean;
  onClose?: () => void;
};
type StateProps = {
  tenants: Tenant[];
};
type DispatchProps = {
  onChange: (id: string) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const Tenants: FC<Props> = (props) => {
  const { isOpen, tenants, onChange, onClose } = props;
  const activeTenants = tenants.filter((tenant) => tenant.user_status === TenantUserStatus.Active);
  const tenantId = EnvCookies.get('tenant');

  const selectedCompany = tenants.find((item) => item.id === tenantId);
  if (activeTenants.length === 1) {
    return (
      <div className={s.option}>
        <CompanyLogo className={s.logo} imgSrc={selectedCompany?.logo} companyName={selectedCompany?.name} size="S" />
        {isOpen && <div className={s.company_name}>{selectedCompany?.company_name}</div>}
      </div>
    );
  }

  return (
    <div className={s.container}>
      <Select
        size="small"
        emptyValue=""
        className={s.select}
        hideMenuIcon={!isOpen}
        value={selectedCompany?.id}
        minMenuWidth={isOpen ? null : 184}
        renderValue={() => (
          <div className={s.option}>
            <CompanyLogo className={s.logo} imgSrc={selectedCompany?.logo} companyName={selectedCompany?.name} size="S" />
            <div className={s.company_name}>{selectedCompany?.company_name}</div>
          </div>
        )}
        onChange={(value) => onChange(value)}
        onClose={onClose}
        options={activeTenants
          .filter((item) => item.id !== tenantId)
          .map((item) => ({ value: item.id, text: item.company_name }))}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  tenants: state.auth.userTenants,
});
const mapDispatchToProps: DispatchProps = {
  onChange: AuthActions.changeCurrentUserTenant,
};
export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
