import { AppState } from 'redux/store';
import { IdName } from 'types/common-types';
import { StateController } from 'state-controller';
import { ProductionStatusEnum } from 'types/status-enums';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';

type OpenModalArgs = {
  productionId: string;
  productionName: string;
  productionIdToLoadDataFor: string;
  page: LocationTheProductionStatusIsChangingFrom;
  tasks: Array<IdName & { status: ProductionStatusEnum }>;
  components: Array<IdName & { status: ProductionStatusEnum }>;
};

export type CancelProductionModalState = {
  isOpen: boolean;
  isLoading: boolean;
  productionId: string;
  productionName: string;
  tasksToCloseIds: string[];
  componentsToCloseIds: string[];
  productionIdToLoadDataFor: string;
  page: LocationTheProductionStatusIsChangingFrom;
  tasks: Array<IdName & { status: ProductionStatusEnum }>;
  components: Array<IdName & { status: ProductionStatusEnum }>;
};

const defaultState: CancelProductionModalState = {
  tasks: [],
  isOpen: false,
  components: [],
  productionId: '',
  isLoading: false,
  productionName: '',
  tasksToCloseIds: [],
  componentsToCloseIds: [],
  productionIdToLoadDataFor: '',
  page: LocationTheProductionStatusIsChangingFrom.ProductionList,
};

const stateController = new StateController<CancelProductionModalState>('CANCEL_PRODUCTION_MODAL', defaultState);

export class CancelProductionActions {
  public static openModal({ page, tasks, components, productionId, productionName, productionIdToLoadDataFor }: OpenModalArgs) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          page,
          tasks,
          components,
          isOpen: true,
          productionId,
          productionName,
          productionIdToLoadDataFor,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static setComponentsAndTasksToClose(componentsIds: string[], tasksIds: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          componentsToCloseIds: componentsIds || [],
          tasksToCloseIds: tasksIds || [],
        })),
      );
    };
  }

  public static onConfirm() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { tasksToCloseIds, componentsToCloseIds, productionId, page, productionIdToLoadDataFor } =
          getState().production.cancelProductionModal;

        await ProductionWorkflowService.cancelProductionTasks(productionId, tasksToCloseIds, componentsToCloseIds);

        if (page === LocationTheProductionStatusIsChangingFrom.ProductionList) {
          await dispatch(ProductionFiltersActions.getProductsByFilter({ showFetchEffect: false }));
        } else if (LocationTheProductionStatusIsChangingFrom.WorkflowItem) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionIdToLoadDataFor }));
        } else {
          await dispatch(
            ProductionWorkflowActions.silentLoad({ id: productionIdToLoadDataFor, disableAdditionalTasksSet: true }),
          );
        }

        dispatch(CancelProductionActions.closeModal());
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }
}

export const reducer = stateController.getReducer();
