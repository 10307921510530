import {
  VariantState,
  ProductState,
  RewardsState,
  PerformersModel,
  DescriptionState,
  UpdateTaskValueT,
  RelatedTasksState,
  ConfigurationState,
} from 'pages/task/types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { notify } from 'notifications';
import {
  ProductFileModel,
  ProductPhotoModel,
  UpdateTaskRewardsBody,
  TaskResponsibilityModel,
  FailReasonsModel,
} from 'services/production-task.model';
import { StateController } from 'state-controller';
import { PriorityEnum } from 'types/priority-enums';
import { UserShortModel } from 'services/user.model';
import { FileItem, IdName } from 'types/common-types';
import { AppState, GetStateFunction } from 'redux/store';
import { TaskSlotService } from 'services/task-slot.service';
import { TaskAssigmentService } from 'services/task-assigment.service';
import { ProductionTaskService } from 'services/production-task.service';
import { TaskTimeTrackingService } from 'services/time-tracking.service';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { TaskResponsibilityService } from 'services/task-responsibility.service';
import { TaskResponsibilityUpdateRequest } from 'services/task-responsibility.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ItemType, BreadcrumbItem } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import { formatFailedBy, formatLaunchedBy, mapRewardsToRewardsState } from 'pages/task/helpers/mappers';
import {
  DeadlineHistoryItem,
  ExternalOrderNumberHistoryItem,
  NoteHistoryItem,
  VariantHistoryItem,
} from 'services/production-workflow.model';
import { t } from '../../setup-localization';
import FireMinusIcon from '../../icons/fire-minus';
import { MODALS } from '../../modules/root-modals/modals';
import { TaskFilesService } from '../../services/task-files.service';
import { ModalActions } from '../../modules/root-modals/root-modals.controller';
import { TaskResponsibilityCreateRequest } from '../../services/task-responsibility.model';
import { DeleteConfirmationOwnProps } from '../../modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { Actions as WorkflowTaskTemplateResponsibilityModalActions } from '../../modules/task-template-responsibility-modal/workflow-task-template-responsibility-modal.controller';

export type TaskState = {
  id: string;
  name: string;
  deadline: string;
  failedBy: string;
  launchedBy: string;
  isLoading: boolean;
  created_at: string;
  started_at: string;
  time_limit: number;
  isUpdating: boolean;
  fail_comment: string;
  productionId: string;
  is_in_queue: boolean;
  is_reopened: boolean;
  isAdditional: boolean;
  rewards: RewardsState;
  product: ProductState;
  variant: VariantState;
  status: TaskStatusEnum;
  priority: PriorityEnum;
  status_changed_at: string;
  total_paused_time: number;
  taskDepartments: IdName[];
  perfomers: PerformersModel;
  productionProgress: number;
  reporting_period_to: string;
  breadcrumbs: BreadcrumbItem[];
  description: DescriptionState;
  reporting_period_from: string;
  total_in_progress_time: number;
  relatedTasks: RelatedTasksState;
  fail_reasons: FailReasonsModel[];
  isReportingPeriodClosed: boolean;
  productMedia: ProductPhotoModel[];
  configuration: ConfigurationState;
  total_in_progress_overtime: number;
  taskAttachments: ProductFileModel[];
  is_reporting_period_closed: boolean;
  is_root_workflow_completed: boolean;
  isShowReportingPeriodWarning: boolean;
  productionStatus: ProductionStatusEnum;
  productAttachments: ProductFileModel[];
  total_paused_out_of_work_time_range: number;
  taskResponsibilities: TaskResponsibilityModel[];
  orderInfo: {
    order_key: string;
    external_order_number: string;
  };
  history: {
    notes: NoteHistoryItem[];
    variantsHistory: VariantHistoryItem[];
    deadlineHistory: DeadlineHistoryItem[];
    externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
  };
  production_workflow: {
    title: string;
    started_at: string;
    deadline_at: string;
    production_key: string;
  };
};

const defaultState: TaskState = {
  breadcrumbs: [],
  productMedia: [],
  taskAttachments: [],
  taskDepartments: [],
  productAttachments: [],
  taskResponsibilities: [],
  id: '',
  name: '',
  deadline: '',
  failedBy: '',
  launchedBy: '',
  started_at: '',
  created_at: '',
  productionId: '',
  fail_comment: '',
  fail_reasons: [],
  status_changed_at: '',
  time_limit: 0,
  total_paused_time: 0,
  total_in_progress_time: 0,
  total_in_progress_overtime: 0,
  total_paused_out_of_work_time_range: 0,
  isLoading: false,
  isUpdating: false,
  is_in_queue: false,
  is_reopened: false,
  isAdditional: false,
  isReportingPeriodClosed: false,
  is_root_workflow_completed: false,
  is_reporting_period_closed: false,
  isShowReportingPeriodWarning: false,
  productionProgress: null,
  reporting_period_to: null,
  reporting_period_from: null,
  status: TaskStatusEnum.To_Do,
  priority: PriorityEnum.Lowest,
  productionStatus: ProductionStatusEnum.To_Do,
  perfomers: {
    users: {
      suggestedUsers: [],
      otherUsers: [],
    },
  },
  product: {
    id: '',
    type: '',
    name: '',
    version: '',
    sourceId: '',
    vendorName: '',
  },
  configuration: {
    id: '',
    sku: '',
    name: '',
  },
  variant: {
    id: '',
    sku: '',
    name: '',
    barcode: '',
  },
  description: {
    text: '',
    isEditing: false,
  },
  rewards: {
    bonuses: [],
    totalBonus: '0',
    basicReward: '0',
    totalReward: '0',
    basicRewardPrev: '0',
    assignmentRewards: [],
  },
  relatedTasks: {
    nextTasks: [],
    failedTasks: [],
    previousTasks: [],
  },
  orderInfo: {
    order_key: '',
    external_order_number: '',
  },
  history: {
    notes: [],
    deadlineHistory: [],
    variantsHistory: [],
    externalOrderNumberHistory: [],
  },
  production_workflow: {
    title: '',
    started_at: '',
    deadline_at: '',
    production_key: '',
  },
};

const stateController = new StateController<TaskState>('TASK_IN_PROGRESS', defaultState);

export class Actions {
  public static init(id: string, spinner = true) {
    return async (dispatch) => {
      try {
        if (spinner) {
          dispatch(stateController.setState({ isLoading: true }));
        }

        const data = await ProductionTaskService.getTaskInfo(id);
        const breadcrumbsMapped = [
          {
            name: 'Production',
            type: 'production',
          },
          ...data.breadcrumbs.map((item) => ({
            ...item,
            isAllowedCopy: true,
            isHaveTooltip: true,
            isDisabled: item.type === ItemType.OrderKey && true,
          })),
        ] as BreadcrumbItem[];

        const previousItemsMapped = data.previous_items.map((item, index) => {
          if ('task' in item) {
            const { id: taskId, name, status, task_key } = item.task;
            return {
              id: taskId,
              name,
              status,
              type: 'task',
              taskKey: task_key,
            };
            // eslint-disable-next-line no-else-return
          } else if ('workflow' in item) {
            const { id: workflowId, workflow_name: name, status, product_name, configuration } = item.workflow;
            return {
              id: workflowId,
              name,
              status,
              type: 'workflow',
              configuration,
              product_name,
            };
          }
          return {
            id: index,
            isEmptySlot: true,
          };
        });

        const nextItemsMapped = data.next_items.map((item) => {
          if ('task' in item) {
            const { id: taskId, name, status, task_key } = item.task;
            return {
              name,
              status,
              id: taskId,
              type: 'task',
              taskKey: task_key,
            };
            // eslint-disable-next-line no-else-return
          } else if ('workflow' in item) {
            const { id: workflowId, workflow_name: name, status } = item.workflow;
            return {
              id: workflowId,
              name,
              status,
              type: 'workflow',
            };
          }
          return null;
        });

        const isShowReportingPeriodWarning =
          (data.is_reopened || Boolean(data.failed_by)) && dayjs().isAfter(dayjs(data.reporting_period_to));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            id: data.id,
            name: data.name,
            status: data.status,
            priority: data.priority,
            deadline: data.deadline_at,
            created_at: data.created_at,
            started_at: data.started_at,
            time_limit: data.time_limit,
            is_in_queue: data.is_in_queue,
            breadcrumbs: breadcrumbsMapped,
            is_reopened: data.is_reopened,
            isAdditional: data.is_additional,
            productMedia: data.product.photos,
            taskAttachments: data.attachments,
            taskDepartments: data.departmets_all,
            fail_reasons: data.fail_reasons,
            fail_comment: data.fail_comment,
            productAttachments: data.product.files,
            productionId: data.production_workflow_id,
            status_changed_at: data.status_changed_at,
            total_paused_time: data.total_paused_time,
            reporting_period_to: data.reporting_period_to,
            failedBy: formatFailedBy(data.failed_by_info),
            launchedBy: formatLaunchedBy(data.launched_by),
            rewards: mapRewardsToRewardsState(data.rewards),
            taskResponsibilities: data.taskResponsibilities,
            reporting_period_from: data.reporting_period_from,
            productionStatus: data.production_workflow.status,
            total_in_progress_time: data.total_in_progress_time,
            productionProgress: data.production_workflow.progress,
            isReportingPeriodClosed: data.is_reporting_period_closed,
            total_in_progress_overtime: data.total_in_progress_overtime,
            is_reporting_period_closed: data.is_reporting_period_closed,
            is_root_workflow_completed: data.is_root_workflow_completed,
            total_paused_out_of_work_time_range: data.total_paused_out_of_work_time_range,
            description: {
              ...prev.description,
              text: data.description,
            },
            product: {
              id: data.product.id,
              name: data.product.name,
              vendor: data.product.product_vendor,
              version: `v.${data.product.version}`,
              type: data?.product?.product_type?.name,
              sourceId: data.product.product_source_id,
              vendorName: data.product.product_vendor?.name,
            },
            configuration: {
              id: data.configuration.id,
              sku: data.configuration.sku,
              name: data.configuration.name,
            },
            variant: {
              id: data.variant.id,
              sku: data.variant.sku,
              name: data.variant.name,
              barcode: data.variant.barcode,
            },
            relatedTasks: {
              ...prev.relatedTasks,
              nextTasks: nextItemsMapped,
              failedTasks: data.failed_tasks,
              previousTasks: previousItemsMapped,
            },
            orderInfo: {
              order_key: data.orderInfo.order_key,
              external_order_number: data.orderInfo.external_order_number,
            },
            history: {
              notes: data.history.notes,
              variantsHistory: data.history.variantsHistory,
              deadlineHistory: data.history.deadlineHistory,
              externalOrderNumberHistory: data.history.externalOrderNumberHistory,
            },
            production_workflow: {
              title: data.production_workflow.title,
              started_at: data.production_workflow.started_at,
              deadline_at: data.production_workflow.deadline_at,
              production_key: data.production_workflow.production_key,
            },
            isShowReportingPeriodWarning,
          })),
        );
      } finally {
        if (spinner) {
          dispatch(stateController.setState({ isLoading: false }));
        }
      }
    };
  }

  public static dispose() {
    return async (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static addSlot(task_responsibility_id: string, task_assignment_id: string | null) {
    return async (dispatch) => {
      const newSlot = await TaskSlotService.createSlot(task_responsibility_id, task_assignment_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            if (responsibility.id === newSlot.task_responsibility_id) {
              return { ...responsibility, taskSlots: [...responsibility.taskSlots, newSlot] };
            }
            return responsibility;
          }),
        })),
      );
    };
  }

  public static openRemoveSlotConfirmationModal(slot_id: string) {
    return async (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete?',
            text: <>Are you sure you want to remove the slot?</>,
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.removeSlot(slot_id)),
          },
        }),
      );
    };
  }

  public static removeSlot(slot_id: string) {
    return async (dispatch) => {
      const removedSlot = await TaskSlotService.removeSlot(slot_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            if (responsibility.id === removedSlot.task_responsibility_id) {
              return { ...responsibility, taskSlots: responsibility.taskSlots.filter((slot) => slot.id !== slot_id) };
            }
            return responsibility;
          }),
        })),
      );
    };
  }

  public static assignUser(user: UserShortModel, slot_id: string) {
    return async (dispatch) => {
      await TaskAssigmentService.assignUser(user.id, slot_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            return {
              ...responsibility,
              taskSlots: responsibility.taskSlots.map((slot) => {
                if (slot.id === slot_id) {
                  return { ...slot, task_assignment_id: user.id, taskAssignment: { user } };
                }
                return slot;
              }),
            };
          }),
        })),
      );
    };
  }

  static updateTask(value: UpdateTaskValueT, isRefreshTask?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const taskState = getState().task.task;

      try {
        dispatch(stateController.setState((prev) => ({ ...prev, ...value })));
        const updateBody = { make_assignment: false, ...value };
        await ProductionTaskService.updateTask(taskState.id, updateBody);

        if (isRefreshTask) await dispatch(Actions.init(taskState.id, false));
      } catch (err) {
        notify.error(err);
        dispatch(stateController.setState(taskState));
      }
    };
  }

  // ====== Files ===================================================================================================================

  public static deleteTaskFile(fileId: string) {
    return async (dispatch) => {
      await TaskFilesService.delete(fileId);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          taskAttachments: prev.taskAttachments.filter((file) => file.id !== fileId),
        })),
      );
    };
  }

  public static openDeleteTaskModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('product_flow.delete_product_file_modal.title')}</>,
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete the file <strong>{name}</strong>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteTaskFile(id)),
          },
        }),
      );
    };
  }

  public static uploadFile(files: File[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { taskAttachments, id: taskId } = getState().task.task;
      if (!files.length) {
        return;
      }

      const onProgress = (e, id) => {
        const newProgress = Math.round((e.loaded / e.total) * 100);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskAttachments: prev.taskAttachments.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  progress: newProgress,
                };
              }
              return item;
            }),
          })),
        );
      };

      const mockFiles: FileItem[] = files.map((file) => ({
        id: `new-${uuidv4()}`,
        name: file.name,
        link: '',
        is_show_by_default: false,
        isUploading: true,
        progress: 0,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          taskAttachments: [...taskAttachments, ...mockFiles].sort((a, b) => a.name.localeCompare(b.name)),
        })),
      );
      const promises = files.map(async (file, i) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('task_id', taskId);

        const newFile = await TaskFilesService.create(formData, (e) => onProgress(e, mockFiles[i].id));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskAttachments: prev.taskAttachments.map((item) => {
              return mockFiles[i]?.id === item.id ? newFile : item;
            }),
          })),
        );
      });
      await Promise.all(promises);
    };
  }

  // ====== Description ===================================================================================================================

  public static updateDescription(value: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const taskId = getState().task.task.id;

        await ProductionTaskService.updateTask(taskId, { description: value });
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      }
    };
  }

  public static toggleIsEditing() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          description: {
            ...prev.description,
            isEditing: !prev.description.isEditing,
          },
        })),
      );
    };
  }

  public static setDescription(text: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          description: {
            ...prev.description,
            text,
          },
        })),
      );
    };
  }

  // ====== Rewards =======================================================================================================================
  // ------ Basic rewards

  public static onChangeBasicReward(value: string) {
    return async (dispatch) => {
      if ((value.match(/\./g) || []).length > 1) return; // more than two characters "."
      const secondChar = value.charAt(1);
      let val: string;

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.]/g).join('');

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            basicReward: numbers,
          },
        })),
      );
    };
  }

  public static saveBasicRewardValue(value: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id, rewards } = getState().task.task;
        if (parseFloat(rewards.basicRewardPrev) === value) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(id, { basic_reward: value });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards) })));
      } catch (err) {
        notify.error(err);
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevBasicRewardValue() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            basicReward: prev.rewards.basicRewardPrev,
          },
        })),
      );
    };
  }

  // ====== Bonuses =====================================================================================================================

  public static onChangeBonusInput(inputId: string, value: string) {
    return async (dispatch) => {
      if ((value.match(/\./g) || []).length > 1) return; // more than two characters "."
      const secondChar = value.charAt(1);
      let val: string;

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.]/g).join('');

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            bonuses: prev.rewards.bonuses.map((bonus) => {
              return bonus.id === inputId ? { ...bonus, value: numbers } : bonus;
            }),
          },
        })),
      );
    };
  }

  public static saveBonusValue(id: string, value: number, forced?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id: taskId, rewards } = getState().task.task;
        const bonusItem = rewards.bonuses.find((i) => i.id === id);

        if (parseFloat(bonusItem.valuePrev) === value && !forced) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(taskId, { bonus: { bonus_id: id, value } });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevBonusValue(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { bonuses } = getState().task.task.rewards;

      const updatedBonuses = bonuses.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: item.valuePrev,
          };
        }
        return item;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            bonuses: updatedBonuses,
          },
        })),
      );
    };
  }

  // ====== Correction sum ===============================================================================================================

  public static onChangeCorrectionValue(id: string, value?: string) {
    return async (dispatch) => {
      let val: string;

      if ((value.match(/\./g) || []).length > 1) return; // more than two characters "."
      if ((value.match(/-/g) || []).length > 1) return; // more than two characters "-"

      const secondChar = value.charAt(1);

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.-]/g).join('');

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma
      if (numbers.indexOf('-') !== -1 && numbers.indexOf('-') !== 0) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            assignmentRewards: prev.rewards.assignmentRewards.map((assignmentReward) => {
              if (assignmentReward.taskAssignmentId === id) {
                return { ...assignmentReward, correctionSum: numbers };
              }

              return assignmentReward;
            }),
          },
        })),
      );
    };
  }

  public static onClearCorrectionClick() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id, rewards } = getState().task.task;

        const correctionSumBody: UpdateTaskRewardsBody['correction_sum'] = rewards.assignmentRewards.map(
          ({ taskAssignmentId }) => ({ task_assignment_id: taskAssignmentId, value: 0 }),
        );

        const updatedRewards = await ProductionTaskService.updateTaskRewards(id, { correction_sum: correctionSumBody });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      }
    };
  }

  public static saveCorrectionValue(id: string, value: number, forced?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id: taskId, rewards } = getState().task.task;
        const taskAssignment = rewards.assignmentRewards.find((i) => i.taskAssignmentId === id);
        const prevValue = parseFloat(taskAssignment.correctionSumPrev);

        if (prevValue === value && !forced) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(taskId, {
          correction_sum: [{ task_assignment_id: id, value }],
        });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevCorrectionValue(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { assignmentRewards } = getState().task.task.rewards;

      const updatedPerformers = assignmentRewards.map((performer) => {
        if (performer.taskAssignmentId === id) {
          return {
            ...performer,
            correctionSum: performer.correctionSumPrev,
          };
        }
        return performer;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            assignmentRewards: updatedPerformers,
          },
        })),
      );
    };
  }

  // ====== Related tasks =================================================================================================================

  public static unassigneUser(prevUser: UserShortModel, slot_id: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
              return {
                ...responsibility,
                taskSlots: responsibility.taskSlots.map((slot) => {
                  if (slot.id === slot_id) {
                    return { ...slot, taskAssignment: null, task_assignment_id: null };
                  }
                  return slot;
                }),
              };
            }),
          })),
        );
        await TaskAssigmentService.unassignUser(slot_id);
      } catch (error) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
              return {
                ...responsibility,
                taskSlots: responsibility.taskSlots.map((slot) => {
                  if (slot.id === slot_id) {
                    return { ...slot, task_assignment_id: prevUser.id, taskAssignment: { user: prevUser } };
                  }
                  return slot;
                }),
              };
            }),
          })),
        );
        notify.error('Somethin went wrong');
      }
    };
  }

  // ====== Responsibilities ==============================================================================================================

  public static createResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access } = getState().workflow_task_template_responsibility_modal;
      const { id, taskResponsibilities } = getState().task.task;

      const getIdArray = <T extends { id: string }[]>(array: T): string[] => array.map((item) => item.id);

      const data: TaskResponsibilityCreateRequest = {
        name: general.name,
        task_id: id,
        reward_id: null,
        assigment_type: general.type,
        responsibility_count: general.workersCount,
        departments: getIdArray(access.selectedDepartments),
        positionTypes: getIdArray(access.selectedPositions),
        users: getIdArray(access.selectedWorkers),
      };

      try {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());

        const newResponsibility = await TaskResponsibilityService.createResponsibility(data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...prev.taskResponsibilities, newResponsibility],
          })),
        );
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(taskResponsibilities));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static updateResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access, currentId } = getState().workflow_task_template_responsibility_modal;
      const { taskResponsibilities } = getState().task.task;

      const getIdArray = <T extends { id: string }[]>(array: T): string[] => array.map((item) => item.id);

      const data: TaskResponsibilityUpdateRequest = {
        name: general.name,
        assigment_type: general.type,
        taskDepartmentRelations: getIdArray(access.selectedDepartments),
        taskPositionTypeRelations: getIdArray(access.selectedPositions),
        taskUserRelations: getIdArray(access.selectedWorkers),
      };

      try {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());
        const updatedResponsibility = await TaskResponsibilityService.updateResponsibility(currentId, data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: prev.taskResponsibilities.map((item) => {
              if (item.id === currentId) {
                return updatedResponsibility;
              }
              return item;
            }),
          })),
        );
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(taskResponsibilities));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static deleteResponsibility(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { taskResponsibilities } = getState().task.task;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: taskResponsibilities.filter((item) => item.id !== id),
          })),
        );

        await TaskResponsibilityService.deleteResponsibility(id);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static openDeleteResponsibilityConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete responsibility',
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete <strong>{name}</strong>
                <br />
                You cannot undo this action.
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteResponsibility(id)),
          },
        }),
      );
    };
  }

  // ====== Time tracking =================================================================================================================

  public static setTrackingInProgress(taskId: string, isEditPermitted: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.In_Progress);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            started_at: data.started_at,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
  }

  public static setTrackingOnHold(taskId: string, isEditPermitted: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.On_Hold);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            total_in_progress_time: data.total_in_progress_time,
            total_in_progress_overtime: data.total_in_progress_overtime,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        notify.error('Somethin went wrong');
      }
    };
  }

  public static setTrackingDone(taskId: string, isEditPermitted: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.Done);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            total_in_progress_time: data.total_in_progress_time,
            total_in_progress_overtime: data.total_in_progress_overtime,
            total_paused_time: data.total_paused_time,
            total_paused_out_of_work_time_range: data.total_paused_out_of_work_time_range,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        notify.error('Somethin went wrong');
      }
    };
  }
}

export class Selectors {
  public static getAssignedUsers(state: AppState) {
    const slots = state.task.task.taskResponsibilities.flatMap((responsibility) => {
      return responsibility.taskSlots;
    });
    return slots.filter((slot) => !!slot.task_assignment_id);
  }

  public static getSlotsForMultiAssigneeSelect(state: AppState) {
    return state.task.task.taskResponsibilities.flatMap((responsibility) =>
      responsibility.taskSlots.map((slot) => ({
        id: slot.id,
        responsibilityId: slot.task_responsibility_id,
        assignedUserId: slot.taskAssignment?.user?.id,
      })),
    );
  }

  public static getAssignmentRewardsByPeriod(state: AppState) {
    const assignment_rewards = state.task.task.rewards.assignmentRewards;

    const assignmentInPeriod = assignment_rewards.filter((user) => user.is_in_current_reporting_period);
    const assignmentOutPeriod = assignment_rewards.filter((user) => !user.is_in_current_reporting_period);
    return {
      assignmentInPeriod,
      assignmentOutPeriod,
    };
  }

  public static getSlots(state: AppState) {
    const slots = state.task.task.taskResponsibilities.flatMap((responsibility) => {
      return responsibility.taskSlots.map((slot) => ({
        id: slot.id,
        responsibilityId: slot.task_responsibility_id,
        assignedUserId: slot.taskAssignment?.user?.id,
        last_name: slot.taskAssignment?.user?.last_name,
        first_name: slot.taskAssignment?.user?.first_name,
        department: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.department,
        position: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.position_type,
        avatar_image_url: slot.taskAssignment?.user?.avatar_image_url,
      }));
    });
    slots.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    return slots;
  }

  public static getUnassignedUsers(state: AppState) {
    const assignedUsers = this.getAssignedUsers(state);
    const { users } = state.task.task.perfomers;
    const unassignedUsers = {
      suggestedUsers: users.suggestedUsers.filter((user) => {
        return !assignedUsers.find((assignedUser) => assignedUser.taskAssignment.user.id === user.id);
      }),
      otherUsers: users.otherUsers.filter((user) => {
        return !assignedUsers.find((assignedUser) => assignedUser.taskAssignment.user.id === user.id);
      }),
    };

    return unassignedUsers;
  }

  public static getOverTotalvalue(state: AppState) {
    const { assignmentRewards, totalReward } = state.task.task.rewards;
    const sum = assignmentRewards
      .map((assignmentReward) => assignmentReward.total)
      .reduce((accumulator, value) => accumulator + value, 0);
    return sum - Number(totalReward);
  }
}

export const reducer = stateController.getReducer();
