import { Paths } from 'routes/paths';
import { AppState } from 'redux/store';
import { UsersIcon } from 'icons/users';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { DiamondsIcon } from 'icons/diamonds';
import { TabLeftIcon } from 'icons/tab-left';
import { SettingsIcon } from 'icons/settings';
import { StatisticIcon } from 'icons/statistic';
import { DepartmentsIcon } from 'icons/departments';
import { DiamondSearchIcon } from 'icons/diamond-search';
import { Selectors as AuthSelectors } from 'redux/auth.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflowsIcon } from 'icons/production-workflows';
import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { useIsPermitted } from 'modules/permission-guard/hooks/use-current-user-permissions';
import Tenants from 'modules/authorized-layout/components/sidebar-menu/component/tenants/tenants';
import { NavigationLink } from 'modules/authorized-layout/components/sidebar-menu/component/navigation-link/navigation-link';
import s from './sidebar-menu.module.scss';

type Props = {
  isMobileMenuOpened: boolean;
  setIsMobileMenuOpened: Dispatch<SetStateAction<boolean>>;
};

export const SidebarMenu = ({ isMobileMenuOpened, setIsMobileMenuOpened }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!!JSON.parse(localStorage.getItem('isMenuOpen')));

  const wrapperRef = useRef<HTMLDivElement>(null);

  const isUserActive = useSelector((state: AppState) => AuthSelectors.isUserActiveInTenant(state));

  const tooltipTitle = isExpanded ? 'Collapse' : 'Expand';
  const isTextExpanded = isExpanded || isOpen || isMobileMenuOpened;

  const toggleSidebarExpandability = () => {
    setIsExpanded((prev) => !prev);
    if (isExpanded && isOpen) setOpen(false);
  };
  const closeMobileMenu = () => setIsMobileMenuOpened(false);

  const handleHoverOnExpandButton = () => {
    if (!isOpen) setOpen(false);
  };

  const handleHoverOnMenu = () => {
    setTimeout(() => {
      if (wrapperRef.current.matches(':hover')) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 300);
  };

  useEffect(() => {
    window.localStorage.setItem('isMenuOpen', JSON.stringify(isExpanded));
    window.dispatchEvent(new Event('storage'));
  }, [isExpanded]);

  const isUsersPagePermitted = useIsPermitted(Permission.webUsersViewEdit, [AccessLevel.access]);
  const isAnalyticsPagePermitted = useIsPermitted(Permission.webAnalyticsView, [AccessLevel.access]);
  const isProductionPagePermitted = useIsPermitted(Permission.webProductionView, [AccessLevel.access]);
  const isSettingsPagePermitted = useIsPermitted(Permission.webSettingsViewEdit, [AccessLevel.access]);
  const isDepartmentsPagePermitted = useIsPermitted(Permission.webDepartmentsViewEdit, [AccessLevel.access]);

  return (
    <div className={s.menu_container} data-is-expanded={isExpanded} data-is-mobile-menu-opened={isMobileMenuOpened}>
      <div
        ref={wrapperRef}
        className={s.menu_wrapper}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        data-is-open={isOpen || isExpanded}
        data-is-mobile-menu-opened={isMobileMenuOpened}
      >
        <header className={s.header} data-is-open={isOpen || isExpanded}>
          <Tenants isOpen={isTextExpanded} onClose={handleHoverOnMenu} />
          <div
            className={s.switch_wrapper}
            onMouseLeave={handleHoverOnMenu}
            onClick={toggleSidebarExpandability}
            onMouseEnter={handleHoverOnExpandButton}
            data-hide-switch={isExpanded && !isOpen}
          >
            <Tooltip title={tooltipTitle} placement="top">
              <div>
                <TabLeftIcon width={14} height={14} data-isopen={isExpanded} className={s.switch_icon} />
              </div>
            </Tooltip>
          </div>
        </header>
        <div className={s.body}>
          <div className={s.item_list}>
            {isUserActive && (
              <>
                {isAnalyticsPagePermitted && (
                  <>
                    <div
                      className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                      onClick={closeMobileMenu}
                    >
                      <NavigationLink path={Paths.Home} name="Analytics" icon={StatisticIcon} isExpandedView={isTextExpanded} />
                    </div>
                    <div className={s.dash} />
                  </>
                )}

                <div
                  className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                  onClick={closeMobileMenu}
                >
                  <NavigationLink
                    icon={DiamondSearchIcon}
                    name="Product search"
                    isExpandedView={isTextExpanded}
                    path={Paths.ProductSearch}
                  />
                  <NavigationLink
                    name="Products"
                    icon={DiamondsIcon}
                    isExpandedView={isTextExpanded}
                    path={`${Paths.Products}/all-products`}
                  />
                </div>

                <div className={s.dash} />

                {isProductionPagePermitted && (
                  <>
                    <NavigationLink
                      name="Production"
                      path={Paths.Production}
                      isExpandedView={isTextExpanded}
                      icon={ProductionWorkflowsIcon}
                    />
                    <div className={s.dash} />
                  </>
                )}

                <div
                  className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                  onClick={closeMobileMenu}
                >
                  {isDepartmentsPagePermitted && (
                    <NavigationLink
                      name="Departments"
                      icon={DepartmentsIcon}
                      isExpandedView={isTextExpanded}
                      path={`${Paths.Departments}`}
                    />
                  )}
                  {isUsersPagePermitted && (
                    <NavigationLink name="Users" icon={UsersIcon} path={`${Paths.Users}`} isExpandedView={isTextExpanded} />
                  )}
                </div>

                <div className={s.dash} />

                <div onClick={closeMobileMenu}>
                  {isSettingsPagePermitted && (
                    <NavigationLink
                      name="Settings"
                      icon={SettingsIcon}
                      isExpandedView={isTextExpanded}
                      path={`${Paths.Settings}`}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={s.extended_hover_zone} onMouseEnter={() => setOpen(true)} />
      </div>
    </div>
  );
};
