import { useDispatch } from 'react-redux';
import { debounce } from 'utils/debounce';
import { Menu, PopoverOrigin } from '@mui/material';
import { PaginatedResponse } from 'types/common-types';
import { UserShortModel, User } from 'services/user.model';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { MouseEvent, useRef, useState, useEffect } from 'react';
import { SearchInput } from 'components/ui-new/inputs/search-input';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/core';
import { SuggestedUsersResponse } from 'services/task-responsibility.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { getUsersWithoutAssigned } from 'components/ui-new/dropdown-user-search-selector/helpers/helpers';
import OptionItem from 'components/ui-new/dropdown-user-search-selector/components/option-item/option-item';
import { getUsersForTask } from 'components/ui-new/dropdown-user-search-selector/helpers/get-users-for-task';
import { MenuButton } from 'components/ui-new/dropdown-user-search-selector/components/menu-button/menu-button';
import { getUsersForWorkflow } from 'components/ui-new/dropdown-user-search-selector/helpers/get-users-for-workflow';
import { ListForTask } from 'components/ui-new/dropdown-user-search-selector/components/list-for-task/list-for-task';
import { ListForWorkflow } from 'components/ui-new/dropdown-user-search-selector/components/list-for-workflow/list-for-workflow';
import s from './dropdown-user-search-selector.module.scss';

export const PAGE_SIZE = 20;
export const UNASSIGNED = 'unassigned';

const skeleton = (
  <div className={s.skeleton}>
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
    <Skeleton className={s.skeleton_item} />
  </div>
);

type Props = {
  value: string;
  users?: User[];
  tooltip?: string;
  addHover?: boolean;
  isAvatarOnly?: boolean;
  isBiggerPopup?: boolean;
  responsibilityId?: string;
  isEditPermitted?: boolean;
  placement?: PopoverOrigin;
  assignedUsersIds?: string[];
  enableStopPropagation?: boolean;
  isTaskPerformerSelect?: boolean;
  currentlySelectedUser?: UserShortModel;
  onOpen?: () => void;
  onClose?: () => void;
  onSelect: (value: string, prev?: UserShortModel, next?: UserShortModel) => void;
  getAllUsers?: (skip?: number, take?: number) => Promise<PaginatedResponse<User[]>>;
  getOtherUsers?: (responsibilityId: string, skip?: number, take?: number) => Promise<PaginatedResponse<UserShortModel[]>>;
  getSuggestedUsers?: (
    responsibilityId: string,
    skip?: number,
    take?: number,
  ) => Promise<SuggestedUsersResponse<UserShortModel[]>>;
};

export default function DropdownUserSearchSelector({
  users,
  value,
  tooltip,
  addHover,
  placement,
  isAvatarOnly,
  responsibilityId,
  isBiggerPopup = true,
  assignedUsersIds = [],
  currentlySelectedUser,
  enableStopPropagation,
  isTaskPerformerSelect,
  isEditPermitted = true,
  onOpen,
  onClose,
  onSelect,
  getAllUsers,
  getOtherUsers,
  getSuggestedUsers,
}: Props) {
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [usersArray, setUsersArray] = useState([]);
  const [prevKeyword, setPrevKeyword] = useState('');
  const [isLastPage, setIsLastPage] = useState(false);
  const [otherUsersArray, setOtherUsersArray] = useState([]);
  const [isLazyProcessing, setIsLazyProcessing] = useState(false);
  const [suggestedUsersArray, setSuggestedUsersArray] = useState([]);
  const [isAllUsersSuggested, setAllUsersSuggested] = useState(false);
  const [isOtherUsersLastPage, setOtherUsersLastPage] = useState(false);
  const [isSuggestedUsersLastPage, setSuggestedUsersLastPage] = useState(false);
  const [selectedUser, setSelectedUser] = useState(currentlySelectedUser || null);

  const rootRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLDivElement>(null);

  const isShowSkeleton = isLazyProcessing && !usersArray.length && !suggestedUsersArray.length && !otherUsersArray.length;
  const noResults = keyword && !suggestedUsersArray.length && !otherUsersArray.length && !usersArray.length && !isLazyProcessing;
  const isShowUnassignedOption =
    (Boolean(suggestedUsersArray.length) || Boolean(otherUsersArray.length) || Boolean(usersArray.length)) && selectedUser;

  const popupState = usePopupState({ variant: 'dialog', popupId: `user-selector}` });
  popupState.close = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleOptionClick = (val: string, newSelected?: UserShortModel) => {
    setIsOpen(false);
    onSelect?.(val, selectedUser, newSelected);
    setSelectedUser(newSelected);
  };

  const handleOnOpenClick = (e: MouseEvent<HTMLDivElement>) => {
    if (enableStopPropagation) e.stopPropagation();
    if (!isEditPermitted) {
      dispatch(PermissionGuardActions.openModal());
      return;
    }

    setIsOpen(true);
    if (!isOpen && onOpen) onOpen();
    setTimeout(() => {
      searchInputRef.current.focus();
    }, 0);
  };

  const handleOnScroll = async (event) => {
    if ((users && !isLastPage) || (!users && (!isOtherUsersLastPage || !isSuggestedUsersLastPage))) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const bottomOffset = scrollHeight - (scrollTop + clientHeight);
      const shouldLoadMore = bottomOffset < 100;

      if (isOpen && shouldLoadMore) {
        debounce(async () => {
          await getUsers();
        }, 200);
      }
    }
  };

  const handleSetKeyword = (val: string) => {
    setKeyword(val);
    setPrevKeyword(keyword);
  };

  const getUsers = async (isKeywordChanged?: boolean) => {
    await setIsLazyProcessing(true);
    if (users) {
      const { usersData, isLast } = await getUsersForWorkflow(usersArray, keyword, isKeywordChanged, getAllUsers);

      if (isKeywordChanged) {
        await setUsersArray([...usersData]);
      } else {
        await setUsersArray([...usersArray, ...usersData]);
      }

      if (isLast) setIsLastPage(true);
    } else {
      const { suggestedUsersData, otherUsersData, isSuggestedUsersLast, isOtherUsersLast, isAllUsersAreSuggested } =
        await getUsersForTask(
          responsibilityId,
          otherUsersArray,
          suggestedUsersArray,
          keyword,
          isKeywordChanged,
          getOtherUsers,
          getSuggestedUsers,
        );

      if (isKeywordChanged) {
        await setOtherUsersArray([...otherUsersData]);
        await setSuggestedUsersArray([...suggestedUsersData]);
      } else {
        await setOtherUsersArray([...otherUsersArray, ...otherUsersData]);
        await setSuggestedUsersArray([...suggestedUsersArray, ...suggestedUsersData]);
      }

      if (isOtherUsersLast) setOtherUsersLastPage(true);
      if (isAllUsersAreSuggested) setAllUsersSuggested(true);
      if (isSuggestedUsersLast) setSuggestedUsersLastPage(true);
    }
    await setIsLazyProcessing(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (!users) getUsers();
      if (users) setUsersArray(users);
    }
    if (!isOpen) {
      setKeyword('');
      setUsersArray([]);
      setPrevKeyword('');
      setIsLastPage(false);
      setOtherUsersArray([]);
      setSuggestedUsersArray([]);
      setOtherUsersLastPage(false);
      setSuggestedUsersLastPage(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (keyword !== prevKeyword && isOpen) {
      setIsLastPage(false);
      setOtherUsersLastPage(false);
      setSuggestedUsersLastPage(false);
      debounce(async () => {
        await getUsers(true);
      }, 300);
    }
  }, [keyword, isOpen]);

  useEffect(() => {
    setSelectedUser(currentlySelectedUser);
  }, [currentlySelectedUser]);

  return (
    <div
      ref={rootRef}
      className={isTaskPerformerSelect ? s.task_performer_container : `${s.container} ${addHover ? s.container_hover : ''}`}
      {...bindTrigger(popupState)}
    >
      <MenuButton
        isOpen={isOpen}
        tooltip={tooltip}
        isAvatarOnly={isAvatarOnly}
        selectedUser={selectedUser}
        onClick={handleOnOpenClick}
        suggestedUsers={suggestedUsersArray}
      />

      <div onClick={(e) => (enableStopPropagation ? e.stopPropagation() : () => {})}>
        <Menu
          {...bindMenu(popupState)}
          open={isOpen}
          disableAutoFocusItem
          transitionDuration={0}
          anchorEl={rootRef.current}
          classes={{ paper: s.menu_container }}
          sx={{ '.MuiMenu-paper': { width: isBiggerPopup ? 400 : 250 } }}
          transformOrigin={{
            vertical: placement?.vertical || 'top',
            horizontal: placement?.horizontal || 'left',
          }}
        >
          <div className={s.search_container}>
            <SearchInput
              size="small"
              value={keyword}
              placeholder="Search"
              inputRef={searchInputRef}
              className={s.search_input}
              onChange={(event) => handleSetKeyword(event.currentTarget.value)}
            />
          </div>

          {isShowSkeleton ? (
            skeleton
          ) : (
            <div className={s.options} data-is-lazy-processing={isLazyProcessing} onScroll={(event) => handleOnScroll(event)}>
              {isShowUnassignedOption && (
                <div className={s.unassigned_user}>
                  <OptionItem
                    noUserMode
                    isUnassign
                    last_name=""
                    first_name="Unassigned"
                    isBiggerPopup={isBiggerPopup}
                    onClick={() => handleOptionClick(UNASSIGNED)}
                  />
                </div>
              )}

              {users ? (
                <ListForWorkflow
                  value={value}
                  keyword={keyword}
                  isBiggerPopup={isBiggerPopup}
                  users={getUsersWithoutAssigned(usersArray, [selectedUser?.id, ...assignedUsersIds])}
                  onOptionClick={(userId, user) => handleOptionClick(userId, user)}
                />
              ) : (
                <ListForTask
                  value={value}
                  keyword={keyword}
                  isBiggerPopup={isBiggerPopup}
                  isAllUsersAreSuggested={isAllUsersSuggested}
                  onOptionClick={(userId, user) => handleOptionClick(userId, user)}
                  otherUsersArray={getUsersWithoutAssigned(otherUsersArray, [selectedUser?.id, ...assignedUsersIds])}
                  suggestedUsersArray={getUsersWithoutAssigned(suggestedUsersArray, [selectedUser?.id, ...assignedUsersIds])}
                />
              )}

              {noResults && <div className={s.no_options_found}>No results</div>}
            </div>
          )}
        </Menu>
      </div>
    </div>
  );
}
